import {
  Avatar,
  Drawer,
  Dropdown,
  Popover,
  Progress,
  Rate,
  Select,
  Tooltip,
  Tag,
  TreeSelect,
} from "antd";
import React, { useEffect, useState } from "react";
import { BsCircleFill, BsChevronDown } from "react-icons/bs";
import { BiGlobe, BiSolidBarChartAlt2 } from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import { RiMoreLine } from "react-icons/ri";
import { PlusOutlined } from "@ant-design/icons";
import { KeyResultDrawer } from "./KeyResultDrawer";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast } from "react-toastify";
import {
  handleCloseCreateKeyResultModal,
  handleCloseOkrDrawer,
  handleOpenCopyOkrModal,
  handleOpenCreateKeyResultModal,
  handleOpenKeyresultDrawer,
  handleOpenMoveOkrModal,
  handleOpenUpdateKeyResultModal,
} from "../../stores/slices/okrSlice";
import KeyResultModal from "./KeyResultModal";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { GoGoal } from "react-icons/go";
import MoveOkrModal from "./Move/MoveOkrModal";
import CopyOkrModal from "./Copy/CopyOkrModal";
import OkrComments from "./Comments/OkrComments";
import { api } from "../global/api";
dayjs.extend(customParseFormat);

const DrawerContainer = ({
  handleToggleDrawer,
  drawerData,
  keyResultDrawerData,
  getOkrData,
  open,
  onClose,
  setOpen,
  showDrawer,
  toggleModal,
  setToggleModal,
  getOkrs,
}) => {
  const [formData, setformData] = useState({
    title: "",
    key_result_type: "",
    unit: "",
    target_number: "",
    initial_number: "",
    description: "",
    owner: [],
    deadline: "",
  });
  const [titleError, setTitleError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [keyResultError, setKeyResultError] = useState(false);
  const [targetError, setTargetError] = useState(false);
  const [initialError, setInitialError] = useState(false);
  const [deadlineError, setDeadlineError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [loadingKey, setLoadingKey] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [diffError, setDiffError] = useState(0);
  const [parentDropdownItems, setParentDropdownItems] = useState([]);
  const Sessions = useSelector((state) => state.user?.sessionList);

  const okrSlice = useSelector((state) => state?.okr);
  const user = useSelector((state) => state.user);
  const roleAccess = user?.roleAccess;
  const dispatch = useDispatch();
  const onChangeTimePicker = (time, timeString) => {
    if (timeString) setDeadlineError(false);
    setformData({ ...formData, deadline: timeString });
  };
  const [owners, setOwners] = useState([]);
  const currentUser = user?.currentUser;
  useEffect(() => {
    if (drawerData?.owners?.length) {
      let tempOwnerList = drawerData?.owners?.map(
        (owner) => owner?.id + " " + owner?.type
      );
      setSelectedOwner(tempOwnerList);
    }
  }, [drawerData]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(handleOpenCreateKeyResultModal(true));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(handleCloseCreateKeyResultModal());
  };
  useEffect(() => {
    if (toggleModal) {
      handleToggleDrawer();
      setIsModalOpen(true);
    }
  }, [toggleModal]);

  const fetchData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await api.get(baseURL + "/assignowners/", {
        company_id: currentEmployee?.company_id,
      });
      setOwners(response.data);
    } catch (error) {
      // setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [okrSlice?.openCreateKeyResultModal]);

  const getParentData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await api.get(baseURL + "/assignparents/", {
        company_id: currentEmployee?.company_id,
        session: okrSlice?.session,
      });
      const tempParentTree = response?.data?.map((goal) => {
        return {
          label: (
            <p className="flex gap-x-2 items-center">
              <button
                className="rounded p-1"
                style={{
                  background:
                    "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                }}
              >
                <GoGoal
                  className="text-md"
                  style={{
                    color: "#B91C1C",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </button>
              {goal?.title}
            </p>
          ),
          value: goal?.goal_id + " " + "goal",
          type: "goal",
        };
      });
      setParentDropdownItems(tempParentTree);
    } catch (error) {}
  };

  useEffect(() => {
    getParentData();
  }, [drawerData]);

  const saveData = async (event) => {
    event.preventDefault();
    let okr_id = drawerData?.okr_id;
    let user_id = [];
    let team_id = [];

    if (formData?.title === "") {
      setTitleError(true);
      return;
    }
    if (formData?.target_number === "") {
      setTargetError(true);
      return;
    }
    if (formData?.initial_number === "") {
      setInitialError(true);
      return;
    }
    if (formData?.unit === "") {
      setUnitError(true);
      return;
    }

    if (formData?.key_result_type === "") {
      setKeyResultError(true);
      return;
    }

    if (formData?.owner?.length) {
      formData?.owner?.forEach((item) => {
        let typeArr = item.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    } else {
      setOwnerError(true);
      return;
    }

    if (formData.deadline === "") {
      toast.warn("Deadline is required", {
        position: "center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDeadlineError(true);
      return;
    }
    if (diffError) {
      return;
    }

    setLoadingKey(true);
    try {
      const result = await api.post(baseURL + "/key_results/", {
        title: formData.title,
        key_result_type: formData.key_result_type,
        unit: formData.unit,
        target_number: formData.target_number,
        initial_number: formData.initial_number,
        description: formData.description,
        deadline: formData.deadline,
        okr_id: okr_id || drawerData?.okr_id,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        user_ownwer_id: currentUser?.employees[user?.currentCompany]?.user_id,
        user_id: user_id,
        team_id: team_id,
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "key_result_created",
        message: "Key Result created succesfully!.",
        id: result?.data?.key_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      handleCancel();
    } catch (err) {
      handleCancel();
      console.log(err);
    } finally {
      handleCancel();
      getOkrData(drawerData?.okr_id);
      getOkrs();
      setformData((prevFormData) => ({
        ...prevFormData,
        title: "",
        key_result_type: "",
        unit: "",
        target_number: "",
        initial_number: "",
        description: "",
        owner: [],
        deadline: "",
      }));
      setLoadingKey(false);
    }
  };

  const updateObjective = async () => {
    let user_id = [];
    let team_id = [];
    let changeData = {};
    const currentEmployee = currentUser?.employees[user?.currentCompany];

    try {
      selectedOwner?.forEach((item) => {
        let typeArr = item?.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });

      changeData = { user_id, team_id };

      console.log("Sending data to API:", {
        okr_id: drawerData?.okr_id,
        company_id: currentEmployee?.company_id,
        session: formData?.session,
        title: formData?.title,
        description: formData?.description,
        ...changeData,
      });

      const res = await api.put(baseURL + `/okr/`, {
        okr_id: drawerData?.okr_id,
        company_id: currentEmployee?.company_id,
        session: formData?.session,
        title: formData?.title,
        description: formData?.description,
        ...changeData,
      });

      getOkrData(drawerData?.okr_id);
      getOkrs();
    } catch (error) {
      console.log("Error in updateObjective:", error);
    }
  };

  useEffect(() => {
    setformData({
      ...formData,
      session: drawerData?.session,
      title: drawerData?.title,
      description: drawerData?.description,
    });
  }, [drawerData?.session]);

  const updateSelectedOwner = async (e) => {
    let user_id = [];
    let team_id = [];
    selectedOwner?.forEach((item) => {
      let typeArr = item?.split(" ");
      if (typeArr[1] === "user") {
        user_id.push(typeArr[0]);
      } else {
        team_id.push(typeArr[0]);
      }
    });

    const currentEmployee = currentUser?.employees[user?.currentCompany];
    try {
      if (e) {
        let typeArr = e?.split(" ");
        if (typeArr[1] === "user") {
          user_id = user_id.filter((id) => id !== typeArr[0]);
        } else {
          team_id = user_id.filter((id) => id !== typeArr[0]);
        } // Filter out e from team_id
      }

      const res = await api.put(baseURL + `/okr/`, {
        okr_id: drawerData?.okr_id,
        company_id: currentEmployee?.company_id,
        user_id,
        team_id,
      });
      getOkrData(drawerData?.okr_id);
      getOkrs();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteOkr = async (okr_id) => {
    const currentEmployee = currentUser?.employees[user?.currentCompany];
    try {
      const res = await api.delete(baseURL + `/okr/`, {
        okr_id: drawerData?.okr_id,
        company_id: currentEmployee?.company_id,
      });
      getOkrs();
      dispatch(handleCloseOkrDrawer());
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteKeyResult = async (key_id) => {
    const currentEmployee = currentUser?.employees[user?.currentCompany];
    try {
      const res = await api.delete(baseURL + `/key_results/`, {
        key_id,
        company_id: currentEmployee?.company_id,
      });
      getOkrData(drawerData?.okr_id);
      getOkrs();
    } catch (error) {
      console.log(error);
    }
  };

  const moveOkr = async () => {
    const okr_id = drawerData?.okr_id;
    const moveData = formData?.parent_val;
    const splitMoveData = moveData?.split(" ");
    if (splitMoveData?.length !== 2) return;

    const parent_id = splitMoveData[0];
    const parent_type = splitMoveData[1];
    try {
      const response = await api.post(baseURL + "/move-jobs/", {
        current_id: okr_id,
        current_type: "okr",
        target_id: parent_id,
        target_type: parent_type,
      });
      getOkrs();
      dispatch(handleCloseOkrDrawer(false));
    } catch (error) {}
  };
  const resetformData = () => {
    setformData((prevFormData) => ({
      ...prevFormData,
      title: "",
      key_result_type: "",
      unit: "",
      target_number: "",
      initial_number: "",
      description: "",
      owner: [],
      deadline: "",
    }));
  };

  return (
    <div className="flex items-center justify-center w-full p-2">
      <Drawer
        title={
          <DrawerHeader
            handleDeleteOkr={handleDeleteOkr}
            roleAccess={roleAccess}
            okr_id={drawerData?.okr_id}
          />
        }
        width={860}
        open={okrSlice?.openOkrDrawer}
        onClose={() => dispatch(handleCloseOkrDrawer())}
        zIndex={1}
      >
        {parentDropdownItems?.length === 0 ? (
          <div className="h-full flex justify-center items-center mx-auto">
            <img src="/assets/images/Group 3.svg" />
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full p-4 gap-y-8 ">
              <div className="flex flex-col w-full gap-y-1">
                <h2 className="flex items-center w-full p-1 text-2xl transition-all border border-transparent rounded hover:border-slate-600 gap-x-2">
                  <button>
                    <img
                      src="/assets/images/objective.svg"
                      style={{
                        width: "27px",
                        height: "27px",
                      }}
                    />
                  </button>
                  <input
                    className="text-2xl p-4 border-none w-full"
                    name="title"
                    value={formData?.title}
                    readOnly={!roleAccess?.Objective?.Update}
                    type="text"
                    onChange={(e) =>
                      setformData({ ...formData, title: e.target.value })
                    }
                    // onBlur={updateObjective}
                    maxLength={70}
                  ></input>
                </h2>
                <div className="flex items-center w-full gap-x-8">
                  <label
                    htmlFor="session-input"
                    className="w-1/6 py-5 text-xs text-slate-700"
                  >
                    Quarter
                  </label>
                  <Select
                    className="w-4/5"
                    value={formData?.session}
                    size="large"
                    open={roleAccess?.Objective?.Update ? undefined : false}
                    onChange={(e) => {
                      setformData({ ...formData, session: e });
                    }}
                  >
                    {Sessions?.map((session, index) => {
                      return (
                        <Select.Option value={session.value} key={index}>
                          {session.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="flex items-center w-full gap-x-8">
                  <label
                    htmlFor="owner-input"
                    className="w-1/6 py-5 text-xs text-slate-700"
                  >
                    Owner
                  </label>
                  <Select
                    className="w-4/5 px-0"
                    title="owner"
                    mode="multiple"
                    value={selectedOwner}
                    open={roleAccess?.Objective?.Update ? undefined : false}
                    removeIcon={selectedOwner.length !== 1 ? undefined : ""}
                    size="large"
                    onChange={(newSelectedValues) => {
                      if (
                        newSelectedValues.length === 0 &&
                        selectedOwner.length === 1
                      ) {
                        return;
                      }
                      setSelectedOwner(newSelectedValues);
                    }}
                    style={{ padding: "10px 0px" }}
                  >
                    {owners?.map((owner, index) => {
                      return (
                        <Select.Option
                          value={owner?.id + " " + owner?.type}
                          key={index}
                        >
                          <div className="flex items-center gap-x-2  select-option-owner">
                            {owner?.type === "user" ? (
                              <div
                                className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                                style={{
                                  backgroundColor: owner?.profile_image
                                    ? "initial"
                                    : getAvatarColor(owner?.name),
                                }}
                                size={18}
                              >
                                {owner?.profile_image ? (
                                  <img
                                    src={owner?.profile_image}
                                    alt="Profile"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      display: "block",
                                    }}
                                  />
                                ) : (
                                  getInitials(owner.name)
                                )}
                              </div>
                            ) : (
                              <span className="p-1 bg-gray-600 rounded-full">
                                <BiGlobe className="text-gray-300 text-xs" />
                              </span>
                            )}
                            <span>{owner.name}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="flex items-center w-full gap-x-8">
                  <label
                    htmlFor="okrdesign-input"
                    className="w-1/6 py-5 text-xs text-slate-700"
                  >
                    Goal-design Score
                  </label>
                  {/* <input id='okrdesign-input ' className='w-4/6 p-5 transition-all border-none hover:border-solid border-slate-600 ' type="text" /> */}
                  <Popover
                    content={content}
                    title={
                      <h3 className="text-xl font-bold">Goal-design Score</h3>
                    }
                    trigger="click"
                    placement="bottom"
                    className="flex items-center cursor-pointer gap-x-2"
                  >
                    <Rate
                      character={<BsCircleFill className="text-[8px]" />}
                      allowHalf
                      value={4.5}
                      disabled

                      // style={{
                      //     fontSize: 36,
                      // }}
                    />
                    <BsChevronDown className="text-[10px]" />
                  </Popover>
                </div>
                <div className="flex items-center w-full gap-x-8">
                  <label
                    htmlFor="parent-input"
                    className="w-1/6 py-5 text-xs text-slate-700"
                  >
                    Parent
                  </label>

                  <TreeSelect
                    showSearch
                    size="large"
                    className="w-4/5"
                    open={roleAccess?.Objective?.Update ? undefined : false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="Assign Parent"
                    allowClear
                    value={
                      formData?.parent_val || drawerData?.goal_id + " " + "goal"
                    }
                    onChange={(newVal) =>
                      setformData({ ...formData, parent_val: newVal })
                    }
                    treeData={parentDropdownItems}
                    // onBlur={moveOkr}
                  />
                </div>
                <div className="flex items-center w-full gap-x-8">
                  <label
                    htmlFor="description-input"
                    className="w-1/6 py-5 text-xs text-slate-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="description-input "
                    name="description"
                    value={formData?.description}
                    onChange={(e) =>
                      setformData({ ...formData, description: e.target.value })
                    }
                    readOnly={!roleAccess?.Objective?.Update}
                    maxLength={150}
                    className="w-5/6 p-3 transition-all border hover:border-solid border-slate-300 rounded  "
                  />
                </div>
                {/* <div className="flex items-center w-full gap-x-8">
                  <label
                    htmlFor="tags-input"
                    className="w-1/6 py-5 text-xs text-slate-700"
                  >
                    Tags
                  </label>
                  <input
                    id="tags-input "
                    className="w-5/6 p-5 transition-all border hover:border-solid border-slate-300 rounded"
                    type="text"
                    readOnly={!roleAccess?.Objective?.Update}
                  />
                </div> */}
                <div className="w-full mt-2 ">
                  <div className="flex justify-end gap-2">
                    {/* <button
                      onClick={() => dispatch(handleCloseOkrDrawer())}
                      className="px-4 py-2 text-gray-700 bg-white rounded border border-gray-300"
                    >
                      Cancel
                    </button> */}
                    <button
                      type="button"
                      onClick={() => updateObjective()}
                      className="px-4 py-2 text-white bg-blue-600 rounded"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="flex items-center justify-between border-b-2">
                  <div className="flex p-2 gap-x-2">
                    <h4 className="flex items-center text-xl text-gray-800 gap-x-2">
                      <span className="p-0.5 bg-purple-200 rounded">
                        <BiSolidBarChartAlt2 className="text-xs text-purple-600" />
                      </span>
                      <span>Progress</span>
                    </h4>
                    <button
                      className="text-blue-600 hover:bg-slate-200 rounded-full p-0.5 px-2 text-base flex items-center justify-center gap-x-2 transition-all"
                      onClick={showModal}
                    >
                      <PlusOutlined /> Add Key Result
                    </button>
                  </div>
                  <div className="">
                    <span className="text-xl text-red-400">
                      {drawerData?.average_gain}%
                    </span>
                  </div>
                </div>

                <div>
                  {drawerData?.children?.map((item, index) => (
                    <div
                      className="grid grid-cols-12 gap-x-5 p-4 border-b-[0.5px] w-full"
                      key={index}
                    >
                      <div className="flex items-start col-span-5 gap-x-2">
                        <div className="">
                          <button>
                            <img
                              src="/assets/images/keyresults.svg"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </button>
                        </div>
                        <div
                          className="flex flex-col items-start w-full"
                          onClick={() => {
                            dispatch(handleOpenKeyresultDrawer(item?.key_id));
                          }}
                        >
                          <h5 className="text-xs font-medium cursor-pointer w-full">
                            {item.title}
                          </h5>
                          <p className="text-xs w-full">{item?.description}</p>
                        </div>
                      </div>
                      <div className="flex items-center col-span-4 gap-x-3">
                        {/* <button className="rounded-md bg-green-500 text-[10px] px-1  text-white uppercase">
                      High
                    </button> */}
                        <Avatar.Group
                          maxCount={2}
                          size={"small"}
                          maxStyle={{
                            color: "#f56a00",
                            backgroundColor: "#fde3cf",
                          }}
                        >
                          {item?.owners?.map((owner, index) =>
                            owner.type === "user" ? (
                              <div
                                key={index}
                                className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                                style={{
                                  backgroundColor: owner?.profile_image
                                    ? "initial"
                                    : getAvatarColor(owner?.name),
                                }}
                                size={18}
                              >
                                {owner?.profile_image ? (
                                  <img
                                    src={owner?.profile_image}
                                    alt="Profile"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      display: "block",
                                    }}
                                  />
                                ) : (
                                  getInitials(owner?.name)
                                )}
                              </div>
                            ) : (
                              <Tooltip key={index} title={owner?.name}>
                                <span className="p-1 bg-gray-600 rounded-full">
                                  <BiGlobe className="text-gray-300 text-sm " />
                                </span>
                              </Tooltip>
                            )
                          )}
                        </Avatar.Group>
                        <div className="flex w-32">
                          <Progress
                            size={"small"}
                            percent={item?.overall_gain}
                          />
                        </div>
                      </div>
                      <div className="flex items-center col-span-3 gap-x-2">
                        <button
                          className="capitalize px-2 py-0.5 rounded-full border border-blue-500 text-xs font-semibold"
                          onClick={() => {
                            dispatch(handleOpenKeyresultDrawer(item?.key_id));
                            dispatch(handleOpenUpdateKeyResultModal(true));
                          }}
                        >
                          Update
                        </button>
                        <Dropdown
                          menu={{
                            items: [
                              {
                                label: "Delete",
                                key: "7",
                                danger: true,
                                onClick: () =>
                                  handleDeleteKeyResult(item?.key_id),
                              },
                            ],
                          }}
                        >
                          <RiMoreLine />
                        </Dropdown>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <OkrComments okr_id={drawerData?.okr_id} />
            </div>
          </>
        )}
      </Drawer>
      <KeyResultDrawer
        open={open}
        setOpen={setOpen}
        showDrawer={showDrawer}
        onClose={onClose}
        keyResultDataKey={keyResultDrawerData}
        getOkrs={getOkrs}
        getOkrData={getOkrData}
        owners={owners}
      />
      <KeyResultModal
        isModalsOpen={isModalOpen}
        handleOk={saveData}
        handleCancel={() => {
          handleCancel();
          resetformData();
        }}
        resetformData={resetformData}
        formData={formData}
        saveData={saveData}
        //  setFormData={setFormData}
        getOkrData={getOkrData}
        owners={owners}
        titleError={titleError}
        setTitleError={setTitleError}
        unitError={unitError}
        setUnitError={setUnitError}
        keyResultError={keyResultError}
        setKeyResultError={setKeyResultError}
        targetError={targetError}
        setTargetError={setTargetError}
        initialError={initialError}
        setInitialError={setInitialError}
        deadlineError={deadlineError}
        setDeadlineError={setDeadlineError}
        //  changeHandeler={changeHandler}
        setformData={setformData}
        onChangeTimePicker={onChangeTimePicker}
        setDiffError={setDiffError}
        diffError={diffError}
        setOwnerError={setOwnerError}
        ownerError={ownerError}
        loadingKey={loadingKey}
      />

      <MoveOkrModal drawerData={drawerData} getOkrs={getOkrs} />
      <CopyOkrModal drawerData={drawerData} getOkrs={getOkrs} />
    </div>
  );
};

export default DrawerContainer;

const DrawerHeader = ({ handleDeleteOkr, okr_id, roleAccess }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center justify-end p-1 gap-x-2">
      <Tooltip title="More Options" placement="left">
        <Dropdown
          menu={{
            items: [
              {
                label: "Move",
                key: "7",
                onClick: () => {
                  dispatch(handleOpenMoveOkrModal({ id: okr_id, type: "okr" }));
                },
              },
              {
                label: "Clone",
                key: "8",
                onClick: () => {
                  dispatch(handleOpenCopyOkrModal({ id: okr_id, type: "okr" }));
                },
              },
              roleAccess?.Objective?.Delete && {
                label: "Delete",
                key: "9",
                danger: true,
                onClick: handleDeleteOkr,
              },
            ],
          }}
        >
          <button className="p-2 transition-colors -rotate-90 rounded hover:bg-slate-100">
            <FiMoreHorizontal className="text-xl" />
          </button>
        </Dropdown>
      </Tooltip>
    </div>
  );
};

const content = () => {
  return (
    <div className="w-[350px]">
      <div className="grid grid-cols-6 p-2 gap-x-4">
        <p className="col-span-4">
          Consider addressing the recommended points below to make your Goal
          top-notch.
        </p>
        <Progress type="circle" percent={75} size={90} />
      </div>
    </div>
  );
};
