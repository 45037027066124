import React from "react";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ReportTable = ({ draftReports, handleDeleteReport }) => {
  const user = useSelector((state) => state?.user);

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-header text-white">
            <th className="py-3 px-4 text-left text-lg font-medium">Name</th>
            <th className="py-3 px-4 text-left text-lg font-medium">Owner(s)</th>
            <th className="py-3 px-4 text-left text-lg font-medium">Type</th>
            <th className="py-3 px-4 text-left text-lg font-medium">Actions</th>
          </tr>
        </thead>
        <tbody>
          {draftReports?.length > 0 ? (
            draftReports?.map((item, i) => (
              <tr key={i} className="border-b">
                <td className="py-4 px-4">
                  <Link
                    className="text-blue-600 hover:text-blue-800"
                    to={"/company/reports/" + item?.report_id}
                  >
                    {item?.name}
                  </Link>
                </td>
                <td className="py-4 px-4">
                  <div className="flex items-center gap-x-2">
                    <div
                      className="w-8 h-8 text-sm border border-black rounded-full flex items-center justify-center text-white"
                      style={{
                        backgroundColor: item?.profile_image
                          ? "initial"
                          : getAvatarColor(item?.username),
                      }}
                    >
                      {item?.profile_image ? (
                        <img
                          src={item?.profile_image}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                            display: "block",
                          }}
                        />
                      ) : (
                        getInitials(item?.username)
                      )}
                    </div>
                    <span>{item?.username}</span>
                  </div>
                </td>
                <td className="py-4 px-4">
                  {item?.type === "key_results" ? "Key Result" : item?.type}
                </td>
                <td className="py-4 px-4">
                  <button
                    onClick={() => {
                      handleDeleteReport(item?.report_id);
                    }}
                    className="bg-white text-red-600 p-2 rounded-lg"
                  >
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="py-4 px-4 text-center">
                No data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
