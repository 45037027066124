import React, { useEffect, useState } from "react";
import {
  getAvatarColor,
  getFormatedDate,
  getInitials,
  getUnitValue,
} from "../../utils/helpers";
import { Alert, Avatar, Button, DatePicker, InputNumber } from "antd";
import { confidenceLevels } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { handleCloseUpdateKeyResultModal } from "../../stores/slices/okrSlice";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { baseURL } from "../../utils/config";
import dayjs from "dayjs";
import { api } from "../global/api";

const UpdateKeyResultDrawer = ({ keyResultData, dataUpdated, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    newVal: null,
    confidenceLevel: null,
    note: null,
    deadline: null,
    updateDate: dayjs(new Date()),
  });
  const [updateError, setUpdateError] = useState({
    newVal: null,
    confidenceLevel: null,
    note: null,
    deadline: null,
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const okrSlice = useSelector((state) => state.okr);
  useEffect(() => {
    if (okrSlice?.updateKeyResultModal) {
      setFormData({
        newVal: null,
        confidenceLevel: null,
        note: null,
        deadline: null,
        updateDate: dayjs(new Date()),
      });
      setUpdateError({
        newVal: null,
        confidenceLevel: null,
        note: null,
        deadline: null,
      });
    }
  }, [okrSlice?.updateKeyResultModal]);

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  console.log("form",formData)
  const handleSubmit = async (e) => {
    console.log("form",formData)
    setLoading(true);
    e.preventDefault();
    if (!formData.newVal) {
      setUpdateError({
        ...updateError,
        newVal: "Required",
      });
      return;
    }
    if (!formData?.confidenceLevel) {
      setUpdateError({
        ...updateError,
        confidenceLevel: "Required",
      });
      return;
    }
    if (!formData?.updateDate) {
      setUpdateError({
        ...updateError,
        deadline: "Required",
      });
      return;
    }
    // return
    try {
      const currentCompany = currentUser?.employees[user?.currentCompany];
      const res = await api.post(baseURL + "/update-key/", {
        key_id: keyResultData?.key_id,
        new_number: formData.newVal,
        company_id: currentCompany?.company_id,
        user_id: currentCompany?.user_id,
        confidence_level: formData.confidenceLevel,
        note: convertToHTML(editorState?.getCurrentContent()),
        changed_at: formData.updateDate?.$d?.toISOString(),
      });
      dataUpdated();

      onCancel();
      dispatch(handleCloseUpdateKeyResultModal());
      // getOkrData()
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <form
      className="w-full flex flex-col py-3 px-3 gap-y-4"
      onSubmit={handleSubmit}
    >
      <div className="w-full flex flex-col items-start gap-y-2">
        <h2 className="text-2xl font-bold">Update Key Result</h2>
        <div className="flex gap-x-4">
          <button>
            <img
              src="/assets/images/keyresults.svg"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </button>
          <h3 className="font-medium text-base text-slate-700">
            {keyResultData?.title}
          </h3>
        </div>
        <Avatar.Group
          maxCount={2}
          size={"small"}
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
        >
          {keyResultData?.owners?.map((owner, index) => {
            return (
              <div
                className="w-5 h-5 border border-black rounded-full flex items-center justify-center text-white text-2xl relative"
                style={{
                  backgroundColor: owner?.profile_image
                    ? "initial"
                    : getAvatarColor(owner?.name),
                }}
                size={18}
              >
                {owner?.profile_image ? (
                  <img
                    src={owner.profile_image}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                      display: "block",
                    }}
                  />
                ) : (
                  getInitials(owner.name)
                )}
              </div>
            );
          })}
        </Avatar.Group>
        <Alert
          style={{ width: "100%" }}
          message={`The hard deadline for this Key Result is ${getFormatedDate(
            keyResultData?.deadline
          )}`}
          type="warning"
          showIcon
        />
      </div>
      <div className="w-full grid grid-cols-6 gap-x-6 py-2 my-3 gap-y-6 items-center max-h-[350px] overflow-y-auto  ">
        <label htmlFor="currentValue" className="col-span-2 text-sm">
          Current value
        </label>
        <p className="col-span-4 text-sm">
          <span className="font-bold">
            {keyResultData?.unit === "%"
              ? `${
                  keyResultData?.current_number || keyResultData?.initial_number
                } ${getUnitValue(keyResultData?.unit)}`
              : `${getUnitValue(keyResultData?.unit)} ${
                  keyResultData?.current_number || keyResultData?.initial_number
                }`}
          </span>

          <span className="capitalize">
            {"  "}
            {keyResultData?.key_result_type}
            {keyResultData?.unit === "%"
              ? ` ${keyResultData?.target_number} ${getUnitValue(
                  keyResultData?.unit
                )}`
              : ` ${getUnitValue(keyResultData?.unit)} ${
                  keyResultData?.target_number
                }`}
          </span>
        </p>
        <label htmlFor="newValue" className="col-span-2 text-sm">
          New Value <span className="text-red-500">*</span>
        </label>
        <div className="col-span-4 flex flex-col">
          <InputNumber
            type="number"
            required
            value={formData.newVal}
            onChange={(e) => {
              setFormData({ ...formData, newVal: e });
              setLoading(false);
              setUpdateError({ ...updateError, newVal: null });
            }}
            addonBefore={getUnitValue(keyResultData?.unit)}
            className="col-span-4"
          />
          {updateError.newVal && (
            <p className="mt-1 text-red-500">New value is required</p>
          )}
        </div>
        <label htmlFor="confidenceLevel" className="col-span-2 text-sm">
          Confidence Level <span className="text-red-500">*</span>
        </label>
        <div className="col-span-4 flex flex-col">
          <div className="col-span-4 flex gap-x-4">
            {confidenceLevels?.map((level, index) => {
              return (
                <div
                  className={`p-2 py-1 ${
                    level.label !== formData.confidenceLevel
                      ? level.color + " " + level.hoverColor
                      : level.activeColor
                  } ${
                    level.textColor
                  } cursor-pointer transition-all duration-300 rounded min-w-[50px]: `}
                  key={index}
                  onClick={() => {
                    setLoading(false);
                    setUpdateError({ ...updateError, confidenceLevel: null });
                    setFormData({ ...formData, confidenceLevel: level.label });
                  }}
                >
                  {level.label}
                </div>
              );
            })}
          </div>
          {updateError.confidenceLevel && (
            <p className="mt-1 text-red-500">Confidence level is required</p>
          )}
        </div>
        <label htmlFor="note" className="col-span-2 text-sm">
          Note
        </label>
        <div className="col-span-4">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbar={{
              options: ["inline"],
            }}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"

            // onEditorStateChange={this.onEditorStateChange}
          />
        </div>
        {/* <textarea className='col-span-4 border' /> */}
        <label htmlFor="updateDate" className="col-span-2 text-sm">
          Update Date <span className="text-red-500">*</span>
        </label>
        <div className="col-span-4 flex flex-col">
          <DatePicker
            className="col-span-4 border"
            value={formData?.updateDate}
            onChange={(e) => {
              setFormData({ ...formData, updateDate: e });
              if (e) {
                setUpdateError({
                  ...updateError,
                  updateDate: null,
                });
              }
            }}
          />
          {updateError.updateDate && (
            <p className="mt-1 text-red-500">Deadline is required</p>
          )}
        </div>
      </div>
      <div className=" flex items-center justify-end gap-x-4">
        <Button onClick={() => dispatch(handleCloseUpdateKeyResultModal())}>
          Cancel
        </Button>
        <Button disabled={loading} type="primary" htmlType="submit">
          Update
        </Button>
      </div>
    </form>
  );
};

export default UpdateKeyResultDrawer;
