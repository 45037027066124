import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function Nav({ selected }) {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
          alignItems={"center"}
        >
          <Box ml={8}>
            <Image
              decoding="asynchronous"
              src={"/assets/images/imongu2.svg"}
              onClick={() => {
                navigate("/");
              }}
              alt="Logo"
              height={"50px"}
              width={"100px"}
            />
          </Box>
          <Flex
            display={{ base: "none", md: "flex" }}
            mx={["4", "8", "12", "16"]}
          >
            <DesktopNav selected={selected} />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          color={"#011824"}
          spacing={6}
        >
          <Button
            as={"a"}
            fontSize={"sm"}
            fontWeight={400}
            variant={"link"}
            href={"/login"}
            color={"#011824"}
            _hover={{ textDecoration: "none" }}
          >
            Log in
          </Button>
          <Button
            as={"a"}
            fontSize={"sm"}
            fontWeight={600}
            textAlign={"center"}
            color={"#FFFFFF"}
            bg={"#F27E0D"}
            href={"/register"}
            _hover={{
              bg: "#F27E0D",
            }}
          >
            Get Started
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav selected={selected} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = ({ selected }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box
          key={navItem.label}
          fontSize={"sm"}
          p={2}
          cursor={"pointer"}
          fontWeight={500}
          color={linkColor}
          _hover={{ textDecoration: "none", color: linkHoverColor }}
        >
          <ScrollLink
            to={navItem.to || "#"}
            onClick={navItem?.onClick}
            spy={navItem.spy}
            smooth={navItem.smooth}
            offset={navItem.offset}
            duration={navItem.duration}
          >
            <Text
              className={
                selected === navItem.label
                  ? "text-orange-500" // Highlight selected with orange
                  : ""
              }
            >
              {navItem.label}
            </Text>
          </ScrollLink>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = ({ selected }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} selected={selected} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, selected }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{ textDecoration: "none" }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
          className={selected === label ? "text-orange-500" : ""}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

// NAV_ITEMS Array
const NAV_ITEMS = [
  {
    label: "Home",
    to: "/",
    onClick: () => {
      window.location.href = "/";
    },
  },
  {
    label: "Pricing",
    to: "pricing",
    spy: true,
    smooth: true,
    offset: -50,
    duration: 500,
  },
  {
    label: "Features",
    to: "features",
    spy: true,
    smooth: true,
    offset: -50,
    duration: 500,
  },
  {
    label: "iMongu Academy",
    children: [
      {
        label: "Executive Leadership",
        // href: '/academy/course1',
        href: "/contactus",
      },
      {
        label: "IT Admin",
        href: "/contactus",
        // href: '/academy/course2',
      },
      {
        label: "Coaching",
        href: "/contactus",
        // href: '/academy/course2',
      },
      // Add more courses as needed
    ],
  },
  {
    label: "Resources",
    onClick: () => {
      window.location.href = "/resources";
    },
  },
];
