import React, { useEffect, useState } from "react";
import PieChartComponent from "../../components/Insights/ExecutiveInsights/PieChartComponent";
import ExecutiveInsightsTable from "../../components/Insights/ExecutiveInsights/ExecutiveInsightsTable";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import DrawerContainer from "../../components/Okrs/Drawer";
import { api } from "../../components/global/api";

const ExecutiveInsights = () => {
  // const [showLoader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      // setTimeout(() => {
      // setLoader(false);
      setState({ run: true, stepIndex: 3 });
      // }, 1200);
    }
  });

  const [goal_progress, setGoal_progress] = useState({});
  const [goal_count, setGoal_count] = useState();
  const [okr_progress, setOkr_progress] = useState({});
  const [okr_count, setOkr_count] = useState();
  const [key_result_progress, setKey_result_progress] = useState({});
  const [key_result_count, setKey_result_count] = useState();
  const [allObjectives, setAllObjectives] = useState([]);
  const [allGoals, setAllGoals] = useState([]);
  const [allKeyResults, setAllKeyResults] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const currentEmployee = currentUser?.employees;
  const [drawerData, setDrawerData] = useState();
  const okrSlice = useSelector((state) => state?.okr);

  const getGraphData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const res = await api.get(baseURL + "/insights/", {
        company_id: currentEmployee?.company_id,
      });
      setAllGoals(res?.data?.goals);
      setAllObjectives(res?.data?.okrs);
      setAllKeyResults(res?.data?.key_results);
      setGoal_progress(res.data.goal_progress);
      setOkr_progress(res.data.okr_progress);
      setKey_result_progress(res.data.key_progress);
      setGoal_count(res.data.totalgoal);
      setOkr_count(res.data.totalokr);
      setKey_result_count(res.data.totalkeyresult);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGraphData();
  }, []);

  const getOkrData = async (okr_id) => {
    try {
      const res = await api.get(baseURL + "/okr/", {
        okr_id: okr_id,
        company_id: currentEmployee[user?.currentCompany]?.company_id,
      });
      setDrawerData(res.data?.children[0]);
      // setGoalOkrData(res.data)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (okrSlice?.openOkrDrawer) {
      getOkrData(okrSlice?.openOkrDrawer);
    }
  }, [okrSlice?.openOkrDrawer]);

  return (
    <div className="relative h-full ">
      <div className="h-full bg-white rounded-lg overflow-y-auto">
        <div className="flex flex-col w-full">
          <div className="grid w-full h-max gap-y-1 fourth_tour">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 items-center justify-center my-4">
              <div
                onClick={() => setSelectedChart("goal")}
                className="cursor-pointer"
              >
                <PieChartComponent
                  title={
                    <p className="text-basic font-semibold text-base">
                      Goal <br /> Progress Distribution
                    </p>
                  }
                  dataApi={goal_progress}
                  count={goal_count}
                  type="Goal"
                />
              </div>
              <div onClick={() => setSelectedChart("objective")}>
                <PieChartComponent
                  title={
                    <p className="text-basic font-semibold text-base">
                      Objective <br /> Progress Distribution
                    </p>
                  }
                  dataApi={okr_progress}
                  count={okr_count}
                  type="Objective"
                />
              </div>
              <div
                onClick={() => setSelectedChart("keyResult")}
                className="cursor-pointer"
              >
                <PieChartComponent
                  title={
                    <p className="text-basic font-semibold text-base">
                      Key Results <br /> Progress Distribution
                    </p>
                  }
                  dataApi={key_result_progress}
                  count={key_result_count}
                  type="Key Result"
                />
              </div>
            </div>
          </div>
        </div>

        {selectedChart === "goal" ? (
          <ExecutiveInsightsTable data={allGoals} type="Goal" />
        ) : selectedChart === "objective" ? (
          <>
            <ExecutiveInsightsTable data={allObjectives} type="Objective" />
            {/* <DrawerContainer getOkrs={() => {}} drawerData={drawerData} /> */}
          </>
        ) : selectedChart === "keyResult" ? (
          <ExecutiveInsightsTable data={allKeyResults} type="Key Result" />
        ) : (
          <ExecutiveInsightsTable data={allGoals} type="Goal" />
        )}
        <DrawerContainer getOkrs={() => {}} drawerData={drawerData} />
      </div>
    </div>
  );
};

export default ExecutiveInsights;
