import React, { useEffect, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { Stack, Radio, RadioGroup } from "@chakra-ui/react";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import { TreeSelect } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { api } from "../../components/global/api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DetailView = ({ data, handleBack }) => {
  const [okrData, setOkrData] = useState([]);
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [templates, setTemplates] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const getStrokeColor = (template_type) => {
    if (template_type === "quarterly_template") {
      return "#92CAC1";
    } else if (template_type === "monthly_template") {
      return "#F4DB77";
    } else {
      return "#EEB0B0";
    }
  };

  const handleSubmit = async () => {
    const payload = {
      template: data?.template,
      schedule_id: data?.id,
      okr_id: formData?.okr_id || null,
      key_result_id: formData?.key_id || null,
      project_status: formData?.project_status,
      answer: formData?.answer,
    };
    if (templates?.useranswer_created) {
      const res = await api.put(baseURL + "/template-answer/", payload);
      if (res.status === 200) {
        toast.success("Template Updated Successfully!");
      } else {
        toast.error("Please Try Again!");
      }
      console.log(res);
    } else {
      const res = await api.post(baseURL + "/template-answer/", payload);
      console.log("res", res);
      if (res.status === 200) {
        toast.success("Template Saved Successfully!");
      } else {
        toast.error("Please Try Again!");
      }
      console.log(res);
    }
    setTimeout(() => {
      handleBack();
    }, 1000);
  };

  const getAnswerForQuestion = (questionId) => {
    const answerObj = (formData?.answer || []).find(
      (ans) => ans.question_id === questionId
    );
    return answerObj ? answerObj.answer : "";
  };

  const formatTimeTo12Hour = (utcTime) => {
    const date = new Date(utcTime);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes?.toString()?.padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const firstThreeMembers = data?.participations
    ?.slice(0, 5)
    .map((member) => member.profile_image); // Use a default image path if `profile_image` is empty
  const remainingMembersCount =
    data?.participations?.length > 5 ? data?.participations?.length - 5 : 0;
  const [formData, setformData] = useState(false);
  const [keyResults, setKeyResults] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [newComment, setNewComment] = useState("");

  const handleInputChange = (value) => {
    setNewComment(value);
  };

  const handleAddComment = ({ sender_id, receiver_id, schedule_id }) => {
    handleSubmitComment({ sender_id, receiver_id, schedule_id });
  };

  const commentData = async () => {
    const response = await api.get(baseURL + "/template-comments/", {
      sender_id: currentEmployee?.user_id,
      receiver_id: data?.user_details?.user_id,
      schedule_id: data?.id,
    });
    setCommentsData(response?.data || []);
  };

  const handleSubmitComment = async ({
    sender_id,
    receiver_id,
    schedule_id,
  }) => {
    const payload = {
      sender_id: sender_id,
      text: newComment,
      receiver_id: receiver_id,
      schedule_id: schedule_id,
    };

    try {
      const response = await api.post(baseURL + "/template-comments/", payload);

      if (response.status === 201) {
        setNewComment("");
        commentData();
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleAnswerChange = (question_id, answer) => {
    setformData((prevFormData) => {
      // Initialize updatedAnswers to an empty array if prevFormData or prevFormData.answer is undefined
      const updatedAnswers = prevFormData?.answer
        ? [...prevFormData.answer]
        : [];

      // Find the index of the existing answer for the given question_id
      const answerIndex = updatedAnswers.findIndex(
        (ans) => ans.question_id === question_id
      );

      if (answerIndex !== -1) {
        updatedAnswers[answerIndex] = {
          ...updatedAnswers[answerIndex],
          answer,
        };
      } else {
        updatedAnswers.push({ question_id, answer });
      }
      return {
        ...prevFormData,
        answer: updatedAnswers,
      };
    });
  };

  useEffect(() => {
    if (templates?.qa) {
      const initialAnswers = templates?.qa?.flatMap((section) =>
        section.question_list.map((question) => ({
          question_id: question.id,
          answer: question.answer || "",
        }))
      );
      const okr = templates?.user_answer?.okr_id
        ? templates.user_answer.okr_id + " okr"
        : "";
      const key = templates?.user_answer?.key_result_id
        ? templates?.user_answer?.key_result_id + " key"
        : "";
      console.log("key", key);
      checkTypeAndSave(okr);

      setformData({
        answer: initialAnswers,
        project_status: templates?.user_answer?.project_status || "on_track",
        okr_val: okr,
        key_val: key,
      });
    }
  }, [templates]);

  const goalsData = async () => {
    try {
      const result = await api.get(baseURL + "/okrs/", {
        goal_id: data?.goal_id,
      });
      const tempOkrTree = result?.data?.map((okr) => {
        return {
          label: (
            <p className="flex gap-x-2 items-center ">
              <button>
                <img
                  src="/assets/images/objective.svg"
                  alt="objective"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </button>
              {okr?.title}
            </p>
          ),
          value: okr?.okr_id + " " + "okr",
          type: "okr",
        };
      });
      setOkrData(tempOkrTree);
      setGoalData(result?.data);
      // setformData((prevFormData) => {
      //   const updatedData = {
      //     ...prevFormData,
      //     key_val: null,
      //     key_id: null,
      //     key_type: null,
      //   };
      //   return updatedData;
      // });
    } catch (error) {
      if (error?.response?.status === 404) {
      }
      console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await api.get(baseURL + "/template-answer/", {
        user_id: currentEmployee?.user_id,
        schedule_id: data?.id,
      });
      setTemplates(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    goalsData();
    fetchData();
    commentData();
  }, []);

  const checkTypeAndSave = (newVal) => {
    if (!newVal) return;
    const splitData = newVal?.split(" ");
    if (splitData?.length !== 2) return;
    if (splitData[1] !== "okr") {
      return;
    }
    const parent_id = splitData[0];
    const parent_type = splitData[1];
    setformData({
      ...formData,
      okr_val: newVal,
      okr_id: parent_id,
      okr_type: parent_type,
    });
    const selectedOkr = goalData?.find((okr) => okr?.okr_id === parent_id);
    const tempKeyTree = selectedOkr?.key_results?.map((key) => {
      return {
        label: (
          <p className="flex gap-x-2 items-center ">
            <button>
              <img
                src="/assets/images/keyresults.svg"
                alt="key"
                style={{
                  width: "27px",
                  height: "27px",
                }}
              />
            </button>
            {key?.title}
          </p>
        ),
        value: key?.key_id + " " + "key",
        type: "key",
      };
    });
    setKeyResults(tempKeyTree || []);
    setformData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        key_val: null,
        key_id: null,
        key_type: null,
      };
      return updatedData;
    });
  };

  const checkObjective = (newVal) => {
    if (!newVal) return;
    const splitData = newVal?.split(" ");
    if (splitData?.length !== 2) return;
    if (splitData[1] !== "key") {
      return;
    }
    const parent_id = splitData[0];
    const parent_type = splitData[1];
    setformData({
      ...formData,
      key_val: newVal,
      key_id: parent_id,
      key_type: parent_type,
    });
  };

  return (
    <CustomScrollbar>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 space-x-5 overflow-y-auto mb-5">
        <div className="p-4 bg-white rounded-lg  md:col-span-5 col-span-7 space-y-5">
          <div className="border-2 border-gray-200 rounded-md p-4">
            <div className="flex items-start justify-between mb-5">
              <div className="flex items-center space-x-4">
                <div
                  className="h-20 border-l-4 "
                  style={{ borderColor: getStrokeColor(data?.template_type) }}
                ></div>
                <div className="flex flex-col items-start space-y-2">
                  <h1
                    className="text-xs font-sans font-semibold"
                    style={{ color: getStrokeColor(data?.template_type) }}
                  >
                    {data?.template_title}
                  </h1>
                  <h1 className="text-3xl font-sans font-semibold">
                    {data?.name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center space-x-8 text-sm text-gray-700 font-sans">
                <h1 className="flex items-center justify-center">
                  <img
                    src={"/assets/images/eos_icon.png"}
                    alt="icon"
                    className="pr-1"
                  />
                  <p>Assign by</p>
                </h1>
                <h1 className="bg-gray-100 rounded-full px-3 py-1">
                  {data?.user_details?.username}
                </h1>
              </div>
            </div>
            <div className="flex items-start justify-between text-sm font-sans">
              <div className="w-1/2">
                <h1 className="font-semibold mb-1">Details</h1>
                <h1 className="text-xs text-gray-500 leading-relaxed tracking-wide flex items-center">
                  <img
                    src={"/assets/images/member.png"}
                    alt="icon"
                    className="pr-1"
                  />
                  Members : {data?.answer_count}
                </h1>
                <h1 className="text-xs text-gray-500 leading-relaxed tracking-wide flex items-center">
                  <img
                    src={"/assets/images/time.png"}
                    alt="icon"
                    className="pr-1"
                  />
                  Time: {formatTimeTo12Hour(data?.start_time)}
                </h1>
              </div>
              <div className="border-l border-gray-200 h-16 w-1/4"></div>
              <div className="w-1/2">
                <h1 className="font-semibold mb-1">Event Days</h1>
                <div className="flex items-start space-x-5">
                  {data?.day_name?.map((day, i) => (
                    <h1
                      index={i}
                      className="text-xs text-gray-600 leading-relaxed tracking-wide bg-gray-200 p-1 px-2 rounded inline-block"
                    >
                      {day}
                    </h1>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className="border-2 border-gray-200 rounded-md p-4 overflow-y-auto"
            style={{
              maxHeight: "450px",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <h1 className="font-sans text-sm font-semibold flex justify-center italic mb-5">
              Answer the following questions with your team.
            </h1>
            <div>
              {templates?.qa?.map((section) => (
                <ul key={section?.question_title_id} className="mb-8">
                  <h1 className="font-sans font-semibold text-lg mb-4">
                    {section?.question_title}
                  </h1>
                  <ol className="list-decimal list-inside space-y-4 mb-16">
                    {section?.question_list?.map((question) => (
                      <li key={question?.id}>
                        <span className="font-medium">{question?.text}</span>
                        <div className="relative h-[130px] ">
                          <ReactQuill
                            value={getAnswerForQuestion(question?.id)}
                            onChange={(content) =>
                              handleAnswerChange(question.id, content)
                            } // Pass content directly
                            placeholder="Type your answer here..."
                            className="my-2 w-full border border-gray-200 bg-gray-100 rounded text-base leading-relaxed" // Added padding class
                            style={{
                              minHeight: "130px",
                              maxHeight: "130px",
                              height: "130px",
                              overflowY: "auto", // Enable vertical scrolling
                            }}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"], // Basic formatting options
                                [{ list: "ordered" }, { list: "bullet" }], // Lists
                                [{ header: [1, 2, 3, false] }], // Headings
                                ["link"], // Link option
                              ],
                            }}
                          />
                        </div>

                        {/* <textarea
                          value={getAnswerForQuestion(question?.id)}
                          className="my-2 w-full p-2 border border-gray-200 bg-gray-100 rounded"
                          placeholder="Type your answer here..."
                         
                          rows={4}
                        /> */}
                      </li>
                    ))}
                  </ol>
                </ul>
              ))}
            </div>
            {/* Comment Section */}
            <div className="bg-[#fff8f8] p-5 rounded">
              <h1 className="font-sans text-2xl font-semibold flex justify-center italic mb-10">
                Comment Section
              </h1>
              <div className="font-sans">
                <ul className="space-y-16 mb-12">
                  {commentsData?.comments?.length > 0 &&
                    commentsData?.comments?.map((comment, index) => (
                      <li
                        key={index}
                        className="flex items-center justify-center"
                      >
                        {parseInt(comment?.sender_id) ===
                        parseInt(currentEmployee?.user_id) ? (
                          <div className="flex w-full items-center justify-end space-x-3">
                            <ReactQuill
                              value={comment?.text} // The comment text to display
                              readOnly={true} // Set to readOnly
                              theme="bubble" // Optional: use bubble theme for a nicer display
                              className="bg-gray-50 border border-gray-300 rounded-md p-2  overflow-y-auto" // Tailwind CSS classes
                            />
                            <div
                              className="w-8 h-8 border text-lg border-black rounded-full flex items-center justify-center text-white  relative"
                              style={{
                                backgroundColor: commentsData?.sender_details
                                  ?.profile_image
                                  ? "initial"
                                  : getAvatarColor(
                                      commentsData?.sender_details?.username
                                    ),
                              }}
                              size={19}
                            >
                              {commentsData?.sender_details?.profile_image ? (
                                <img
                                  src={
                                    commentsData?.sender_details?.profile_image
                                  }
                                  className="w-full h-full object-cover rounded-full"
                                  alt="Profile"
                                />
                              ) : (
                                getInitials(
                                  commentsData?.sender_details?.username
                                )
                              )}
                            </div>
                            <div className="flex flex-col">
                              <h1 className="font-semibold text-sm">
                                {commentsData?.sender_details?.username}
                              </h1>
                              <h1 className="text-gray-600 font-semibold text-sm">
                                {commentsData?.sender_details?.emails}
                              </h1>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-start space-x-3">
                            <div
                              className="w-8 h-8 border text-lg border-black rounded-full flex items-center justify-center text-white  relative"
                              style={{
                                backgroundColor: data?.user_details
                                  ?.profile_image
                                  ? "initial"
                                  : getAvatarColor(
                                      data?.user_details?.username
                                    ),
                              }}
                              size={30}
                            >
                              {data?.user_details?.profile_image ? (
                                <img
                                  src={data?.user_details?.profile_image}
                                  className="w-full h-full object-cover rounded-full"
                                  alt="Profile"
                                />
                              ) : (
                                getInitials(data?.user_details?.username)
                              )}
                            </div>
                            <div className="flex flex-col">
                              <h1 className="font-semibold text-sm">
                                {currentEmployee?.username}
                              </h1>
                              <h1 className="text-gray-600 font-semibold text-sm">
                                {currentEmployee?.email}
                              </h1>
                            </div>
                            <ReactQuill
                              value={comment?.text} // The comment text to display
                              readOnly={true} // Set to readOnly
                              theme="bubble" // Optional: use bubble theme for a nicer display
                              className="bg-gray-50 border border-gray-300 rounded-md p-2  overflow-y-auto" // Tailwind CSS classes
                            />
                          </div>
                        )}
                      </li>
                    ))}
                </ul>

                <div className="mt-8 p-4 border-t border-gray-100 flex justify-center items-center space-x-32">
                  <div className="flex flex-col space-y-4 w-full">
                    <ReactQuill
                      value={newComment}
                      onChange={handleInputChange}
                      placeholder="Write your comment here..."
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          ["bold", "italic", "underline"],
                          ["link", "image"],
                          ["clean"], // remove formatting button
                        ],
                      }}
                      className="border border-gray-200 rounded-md bg-gray-50 text-sm focus:outline-none"
                    />
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <div
                          className="w-8 h-8 border text-lg border-black rounded-full flex items-center justify-center text-white  relative"
                          style={{
                            backgroundColor: currentUser?.profile_image
                              ? "initial"
                              : getAvatarColor(currentUser?.username),
                          }}
                        >
                          {currentUser?.profile_image ? (
                            <img
                              src={currentUser?.profile_image}
                              className="w-full h-full object-cover rounded-full"
                              alt="Profile"
                            />
                          ) : (
                            getInitials(currentUser?.username)
                          )}
                        </div>
                        <div className="flex flex-col">
                          <h1 className="font-semibold text-sm">
                            {currentUser?.username}
                          </h1>
                        </div>
                      </div>
                      <button
                        disabled={!newComment}
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddComment({
                            sender_id: commentsData?.sender_details?.id,
                            receiver_id: commentsData?.receiver_details?.id,
                            schedule_id: data?.id,
                          });
                        }}
                        className="bg-blue-500 text-white p-2 px-4 rounded-md hover:bg-blue-600 text-xs"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" space-x-8 justify-end hidden md:block">
            <button
              onClick={handleBack}
              className="bg-gray-200 text-gray-800 px-8 py-2 rounded-full hover:bg-gray-300"
            >
              Close
            </button>
            <button
              onClick={handleSubmit}
              className="bg-orange-200 text-orange-500 px-8 py-2 rounded-full hover:bg-orange-300"
            >
              Save
            </button>
          </div>
        </div>
        <div className="p-4 bg-white rounded-lg col-span-7 md:col-span-2 w-full">
          <div className="flex items-center space-x-5 font-sans mb-8">
            <div
              className="flex items-center justify-center bg-gray-50 rounded-full w-14 h-14 text-3xl border-black  font-bold"
              style={{
                backgroundColor: data?.user_details?.profile_image
                  ? "initial"
                  : getAvatarColor(data?.user_details?.username),
              }}
            >
              {data?.user_details?.profileimage ? (
                <img
                  src={data?.user_details?.profile_image}
                  className="w-full h-full object-cover rounded-full"
                  alt="Profile"
                />
              ) : (
                getInitials(data?.user_details?.username)
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <h1 className="font-semibold text-lg">
                {data?.user_details?.username}
              </h1>
              <h1 className="text-gray-600 font-semibold">
                {data?.user_details?.role_name}
              </h1>
            </div>
          </div>
          <h1 className="text-lg font-semibold font-sans mt-6">Members</h1>
          <div className="relative mt-4">
            <div className="flex items-center">
              {firstThreeMembers?.map((member, index) => (
                <div
                  key={index}
                  className="relative w-10 h-10"
                  style={{ left: `${index * -10}px` }}
                >
                  {member ? (
                    <img
                      src={member}
                      alt={`Member ${index + 1}`}
                      className="object-cover w-full h-full rounded-full border border-white"
                    />
                  ) : (
                    <span
                      className="flex items-center justify-center w-full h-full rounded-full  border-black "
                      style={{
                        backgroundColor: getAvatarColor(
                          data?.participations[index]?.username
                        ),
                      }}
                    >
                      {getInitials(data?.participations[index]?.username)}{" "}
                    </span>
                  )}
                </div>
              ))}
              {remainingMembersCount > 0 && (
                <div
                  className="relative bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-sm"
                  style={{ left: `${firstThreeMembers?.length * -10}px` }}
                >
                  +{remainingMembersCount}
                </div>
              )}
            </div>
          </div>
          <hr className="my-5 border border-gray-200" />
          <div className="space-y-2">
            <h1 className="text-lg font-semibold font-sans flex items-center">
              <img
                src={"/assets/images/objective.png"}
                alt="objective"
                className="pr-1"
              />
              Objective Name
            </h1>
            <TreeSelect
              showSearch
              size="large"
              className="w-full h-1/3"
              value={formData?.okr_val}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
                zIndex: 1050,
                textAlign: "start",
              }}
              placeholder="Select Objective"
              allowClear
              onChange={(newVal) => checkTypeAndSave(newVal)}
              treeData={okrData}
            />
          </div>
          <div className="space-y-2 mt-5">
            <h1 className="text-lg font-semibold font-sans flex items-center">
              <img
                src={"/assets/images/key_result.png"}
                alt="objective"
                className="pr-1"
              />
              Key Results Name
            </h1>
            <TreeSelect
              showSearch
              size="large"
              className="w-full h-1/3"
              value={formData?.key_val}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
                zIndex: 1050,
                textAlign: "start",
              }}
              placeholder="Select Key Result"
              allowClear
              onChange={(newVal) => {
                if (newVal && keyResults.length > 0) {
                  checkObjective(newVal);
                }
              }}
              treeData={keyResults}
            />
          </div>
          <div className="mt-8">
            <h1 className="font-sans font-semibold">Project Status</h1>
            <p className="text-xs text-gray-400 font-sans mt-1">
              Update the Project Project Status
            </p>
            <RadioGroup
              onChange={(value) =>
                setformData((prevFormData) => ({
                  ...prevFormData,
                  project_status: value,
                }))
              }
              value={formData.project_status}
            >
              <Stack spacing={2} direction="column" className="mt-5 font-sans">
                <div className="items-center bg-green-50 text-green-500 px-3 py-2 rounded-md inline-flex">
                  <Radio
                    value="on_track"
                    colorScheme="green"
                    className="mr-2"
                    style={{
                      borderColor: "#48bb78",
                    }}
                  />
                  <span className="text-sm">On Track</span>
                </div>
                <div className="items-center bg-red-50 text-red-500 px-3 py-2 rounded-md inline-flex">
                  <Radio
                    value="off_track"
                    colorScheme="red"
                    className="mr-2"
                    style={{
                      borderColor: "#f56565",
                    }}
                  />
                  <span className="text-sm">Off Track</span>
                </div>
                <div className="items-center bg-yellow-50 text-yellow-500 px-3 py-2 rounded-md inline-flex">
                  <Radio
                    value="behind"
                    colorScheme="yellow"
                    className="mr-2"
                    style={{
                      borderColor: "#ecc94b",
                    }}
                  />
                  <span className="text-sm">Behind</span>
                </div>
                <div className="items-center bg-blue-50 text-blue-500 px-3 py-2 rounded-md inline-flex">
                  <Radio
                    value="in_progress"
                    colorScheme="blue"
                    className="mr-2"
                    style={{
                      borderColor: "#3182ce",
                    }}
                  />
                  <span className="text-sm">In Progress</span>
                </div>
              </Stack>
            </RadioGroup>
          </div>
        </div>
        <div className="flex-col block md:hidden mt-8 justify-end">
          <button
            onClick={handleBack}
            className="bg-gray-200 text-gray-800 px-8 py-2 rounded-full hover:bg-gray-300"
          >
            Close
          </button>
          <button
            onClick={handleSubmit}
            className="bg-orange-200 text-orange-500 px-8 py-2 rounded-full hover:bg-orange-300"
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </CustomScrollbar>
  );
};

export default DetailView;
