import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import RoleAccess from "./RoleAccess";
import AccessManagement from "./AccessManagement";
import KPIMetrics from "./KPIMetrics";
import OrganizationHierarchy from "./OrganizationHierarchy";
import BilingPage from "../Billing/BilingPage";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "@chakra-ui/react";
import { Modal, Select } from "antd";
import { handleCreateCloseRoleDrawer } from "../../stores/slices/okrSlice";
import { baseURL } from "../../utils/config";
import { api } from "../../components/global/api";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import ContactUs from "../../components/ContactUs";

const Setting = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "Role Access";
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const roleAccess = user?.roleAccess;
  const okrSlice = useSelector((state) => state?.okr);
  const [reportError, setReportError] = useState(false);
  const [data, setData] = useState([]);
  const roleName = currentUser?.employees[user?.currentCompany]?.rolename;
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      setState({ run: true, stepIndex: 5 });
    }
  });

  const fetchData = async () => {
    try {
      const response = await api.get(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSearchParams({ tab: selectedTab });
    fetchData();
  }, [selectedTab, setSearchParams]);

  const [formData, setFormData] = useState({
    role: "",
  });

  const changeHandler = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      role: value,
    }));
  };

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "Setting",
        linkLocation: `settings/${selectedTab}`,
        ClickLink: `settings / ${selectedTab}`,
      })
    );
  }, [dispatch, selectedTab]);

  const handleOk = (e) => {
    e.preventDefault();
    dispatch(handleCreateCloseRoleDrawer());
  };
  const handleCancel = () => {
    toast.dismiss();
    setFormData({
      role: "",
    });
    dispatch(handleCreateCloseRoleDrawer());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!formData?.role) {
        return;
      }

      const res = await api.post(baseURL + "/role/", {
        role_name: formData?.role,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      setLoading(false);
      setFormData({
        role: "",
      });
      toast.success("Created Role Successfully");
      fetchData();
      setTimeout(() => {
        toast.dismiss();
        handleCancel();
      }, 1000);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status == 404) {
        toast.error("Please Try Again");
        setFormData({
          role: "",
        });
        setTimeout(() => {
          toast.dismiss();
          handleCancel();
        }, 1000);
      } else {
        setFormData({
          role: "",
        });
        toast.error("Please try again");
        setTimeout(() => {
          toast.dismiss();
          handleCancel();
        }, 1000);
      }
    } finally {
    }
  };

  const tabs = [
    "Role Access",
    "Access Management",
    "Organization Hierarchy",
    "Billing",
    "Contact Us",
  ];

  const shouldIncludeOrgHierarchy =
    roleAccess?.["Organization-Hierarchy"]?.View;

  const filteredTabs =
    roleName === "Admin"
      ? tabs
      : tabs.filter((tab) => {
          if (shouldIncludeOrgHierarchy) {
            return tab !== "Access Management" && tab !== "Billing";
          } else {
            return (
              tab !== "Access Management" &&
              tab !== "Billing" &&
              tab !== "Organization Hierarchy"
            );
          }
        });

  return (
    <div className="relative h-screen p-4">
      <div className="flex items-center space-x-16 px-5">
        {filteredTabs?.map((tab) => (
          <h1
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`relative cursor-pointer ${
              selectedTab === tab ? "text-orange-500 font-sans" : ""
            }`}
          >
            {tab}
            {selectedTab === tab && (
              <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
            )}
          </h1>
        ))}
      </div>
      <div className="border-b-2 border-gray-300 mb-8 mt-2"></div>

      <div
        className="flex flex-col p-4 overflow-y-scroll"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
          maxWidth: "calc(100vw - 300px)",
        }}
      >
        {selectedTab === "Role Access" && (
          <RoleAccess assignRole={data} fetchRole={fetchData} />
        )}
        {selectedTab === "Access Management" && (
          <AccessManagement
            data={data}
            setData={setData}
            fetchData={fetchData}
          />
        )}
        {/* {selectedTab === "KPI & Metrics" && <KPIMetrics />} */}
        {selectedTab === "Organization Hierarchy" && <OrganizationHierarchy />}
        {selectedTab === "Billing" && <BilingPage />}
        {selectedTab === "Contact Us" && <ContactUs />}
      </div>
      <Modal
        title=""
        open={okrSlice?.openCreateRoleDrawer}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        footer={null}
      >
        <form className="p-6" onSubmit={handleSubmit}>
          <div className="mb-2">
            <h1 className="p-1 text-3xl font-bold text-black">Create Role</h1>
          </div>
          <hr />

          <div className="grid items-center grid-cols-6 py-4 below-div gap-y-4 gap-x-4">
            <label className="col-span-2 font-semibold text-gray-600">
              Role Name*
            </label>
            <div className="col-span-4">
              <input
                className="w-full p-4 text-sm border border-gray-300 rounded-lg"
                type="text"
                name="role"
                id="role"
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value !== " " &&
                    /[a-zA-Z0-9]/.test(value) &&
                    !/^[\s]+$/.test(value)
                  ) {
                    changeHandler(e);
                  } else if (value === "") {
                    changeHandler(e);
                  }
                }}
                value={formData?.role}
                placeholder="Enter Role Name"
                required
                maxLength={150}
              />
            </div>

            <div className="flex items-center justify-end col-span-6 gap-x-4">
              <button
                className="px-4 py-2 border rounded-xl border-black hover:text-blue-500 hover:border-blue-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleCancel();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 border rounded-xl text-white bg-button hover:bg-button-hover"
                disabled={loading}
              >
                Create Role
                {loading && (
                  <Spinner size="sm" color="white" className="ml-2" />
                )}
              </button>
            </div>
          </div>
          <ToastContainer />
        </form>
      </Modal>
    </div>
  );
};

export default Setting;
