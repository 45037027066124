import React from "react";
import { formatDate } from "../../utils/helpers";

const EmployeeHeader = ({ companyName, initials, Name, Title }) => {
  const currentTime = new Date().getHours();
  let greeting;

  if (currentTime >= 5 && currentTime < 12) {
    greeting = "Good Morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  return (
    <div className="flex flex-wrap items-center justify-between mb-4">
      <div
        className="flex flex-col sm:flex-row items-start sm:items-center"
        style={{ color: "#0F7BB3", opacity: "80%" }}
      >
        {Name ? (
          <div className="text-lg sm:text-2xl font-semibold  opacity-80">
            {greeting}, {Name}
          </div>
        ) : initials ? (
          <div className="flex items-center gap-4">
            <div
              className="w-10 h-10 border rounded-full flex items-center justify-center text-lg"
              style={{
                color: "#0F7BB3",
                borderColor: "#0F7BB3",
              }}
            >
              {initials}
            </div>
            <div className="text-lg sm:text-2xl font-semibold">
              {companyName}
            </div>
          </div>
        ) : (
          <div className="text-lg sm:text-2xl font-semibold">{Title}</div>
        )}
        <svg
          className="hidden sm:block ml-4"
          width="118"
          height="25"
          viewBox="0 0 118 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            strokeWidth: "1.32067",
            verticalAlign: "middle",
          }}
        >
          <path
            d="M1 18.587C5.7027 16.2018 11.4706 17.5503 16.6757 19.5737C43.9868 30.191 32.5107 7.20981 48.027 7.20981C59 7.20981 59.7838 33.0617 74.6757 11.3311C89.5676 -10.3994 80.1622 23.7467 95.0461 23.7467C109.237 23.7467 104.675 7.20981 117 1.58984"
            stroke="url(#paint0_linear_385_2121)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_385_2121"
              x1="16.225"
              y1="18.946"
              x2="117.186"
              y2="-14.0898"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEF7F1" stopOpacity="0" />
              <stop offset="0.749947" stopColor="#FB4646" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <span
        className="mt-2 sm:mt-0 text-sm sm:text-base font-medium"
        style={{
          color: "#419EBD",
        }}
      >
        {formatDate()}
      </span>
    </div>
  );
};

export default EmployeeHeader;
