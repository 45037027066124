import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../utils/config";
import { Button, Checkbox } from "antd";
import WestIcon from "@mui/icons-material/West";
import { api } from "../../components/global/api";

const AssignRoleSection = ({
  role,
  handleBackClick,
  handleSaveAssign,
  updates,
  setUpdates,
  handleCancel
}) => {
  const [expandAll, setExpandAll] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const [selectedSubRoles, setSelectedSubRoles] = useState({});
  const [loading,setLoading] = useState(true);
  const [roles, setRoles] = useState(null);
  const [activities, setActivities] = useState();

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const response = await api.get(baseURL + "/activity-list/");
        setActivities(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchActivity();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        role_id: role?.role_id,
      });
      setRoles(response?.data);
      // Initialize selected roles and subroles after data is fetched
      const initialSelectedRoles = [];
      const initialSelectedSubRoles = {};

      response?.data?.forEach((role) => {
        const allActivitiesSelected = role?.activities?.every(
          (activity) => activity?.activity_status
        );
        if (allActivitiesSelected) {
          initialSelectedRoles?.push(role?.feature_id);
        }
        const selectedActivities = role?.activities
          .filter((activity) => activity?.activity_status)
          .map((activity) => activity?.activity_id);

        if (selectedActivities?.length > 0) {
          initialSelectedSubRoles[role?.feature_id] = selectedActivities;
        }
      });

      setSelectedRoles(initialSelectedRoles);
      setSelectedSubRoles(initialSelectedSubRoles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [role?.role_id, currentUser, user]);

  const handleCheckboxChange = (featureId, activityName, checked) => {
    setLoading(false);
    setRoles((prevRoles) =>
      prevRoles.map((role) => {
        if (role.feature_id === featureId) {
          return {
            ...role,
            activities: role.activities.map((activity) =>
              activity.activity_name === activityName
                ? { ...activity, activity_status: checked }
                : activity
            ),
          };
        }
        return role;
      })
    );

    setUpdates((prevUpdates) => {
      const role = roles.find((role) => role.feature_id === featureId);
      const roleActivity = role.activities.find(
        (activity) => activity.activity_name === activityName
      );

      const existingUpdateIndex = prevUpdates.findIndex(
        (update) =>
          update.feature_id === featureId &&
          update.activity_name === activityName
      );

      const newUpdate = {
        feature_id: featureId,
        activity_name: activityName,
        activity_status: checked,
        activity_id: roleActivity.activity_id,
      };

      if (existingUpdateIndex !== -1) {
        const updatedUpdates = [...prevUpdates];
        updatedUpdates[existingUpdateIndex] = newUpdate;
        return updatedUpdates;
      }
      return [...prevUpdates, newUpdate];
    });
  };

  return (
    <div>
      <div className="flex flex-col pb-5 border-b border-gray-300 overflow-x-hidden">
        <div className="flex items-center space-x-2 mb-4">
          <WestIcon
            className="cursor-pointer"
            fontSize="small"
            onClick={handleBackClick}
          />
          <h2 className="text-xl font-semibold text-center ">Assign Role</h2>
        </div>
        <div className="flex flex-col space-y-5 ">
          <table
            style={{
              borderCollapse: "collapse",
              margin: "0 auto",
              border: "1px solid black",
              fontSize: "0.9rem",
              width: "80%",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid black", padding: "6px" }}>
                  Feature
                </th>
                {activities?.map((activity, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      padding: "6px",
                    }}
                  >
                    {activity}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {roles?.map((role) => (
                <tr key={role.feature_id}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      fontSize: "medium",
                    }}
                  >
                    {role.feature_name}
                  </td>
                  {activities?.map((activity) => {
                    const roleActivity = role.activities?.find(
                      (a) => a.activity_name === activity
                    );

                    return (
                      <td key={activity} style={{ border: "1px solid black" }}>
                        {roleActivity ? (
                          <div className="flex justify-center items-center">
                            <Checkbox
                              checked={roleActivity?.activity_status}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  role.feature_id,
                                  activity,
                                  e.target.checked
                                )
                              }
                            />
                          </div>
                        ) : (
                          <p className="flex justify-center items-center">-</p>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-end space-x-4">
            <Button
              style={{
                backgroundColor: "white",
                color: "#2563EB",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "white";
                e.currentTarget.style.color = "#2563EB";
                e.currentTarget.style.borderColor = "#3B82F6";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "white";
                e.currentTarget.style.color = "#2563EB";
                e.currentTarget.style.borderColor = "#3B82F6";
              }}
              onClick={(e) => {
                e.preventDefault();
                handleCancel(); 
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "blue",
                color: "white",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#2563EB";
                e.currentTarget.style.color = "white";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#3B82F6";
                e.currentTarget.style.color = "white";
              }}
              onClick={(e) => {
                e.preventDefault();
                handleSaveAssign(role?.role_id);
              }}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignRoleSection;
