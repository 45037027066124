import React, { useEffect, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import SortIcon from "@mui/icons-material/Sort";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StopIcon from "@mui/icons-material/Stop";
import TopicIcon from "@mui/icons-material/Topic";
import CircleIcon from "@mui/icons-material/Circle";
import { DatePicker } from "antd";
import { Select } from "antd";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import TemplateModal from "../Touchbases/TemplateModal";
import { api } from "../../components/global/api";
import { useMediaQuery } from "@chakra-ui/react";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AdminView = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const Sessions = useSelector((state) => state.user?.sessionList);
  const [filterSession, setFilteredSession] = useState(Sessions[0].value);
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const handlePrevious = () => {
    const currentIndex = Sessions.findIndex(
      (session) => session.value === filterSession
    );
    const previousIndex =
      (currentIndex - 1 + Sessions.length) % Sessions.length;
    setFilteredSession(Sessions[previousIndex].value);
  };

  const isMobile = useMediaQuery({ maxWidth: 920 });

  const handleNext = () => {
    setFilteredSession((prevValue) => {
      const currentIndex = Sessions.findIndex(
        (session) => session.value === prevValue
      );
      const nextIndex = (currentIndex + 1) % Sessions.length;
      return Sessions[nextIndex].value;
    });
  };

  const currentSession = Sessions.find(
    (session) => session.value === filterSession
  );

  function convertDateToFormat(dateStr) {
    if (dateStr) {
      const date = new Date(dateStr);
      if (!isNaN(date.getTime())) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear());

        return `${year}-${month}-${day}`;
      }
    }

    return null;
  }

  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTemplateOpen = async ({
    participant_id,
    schedule_id,
    template_type,
    template_title,
    template,
  }) => {
    try {
      const response = await api.get(
        `${baseURL}/template-answer/?user_id=${participant_id}&schedule_id=${schedule_id}`
      );
      let stroke, primaryColor, secondaryColor;

      if (template_type === "quarterly_template") {
        stroke = "#92CAC1";
        primaryColor = "#F7FFFE";
        secondaryColor = "#CEF7F0";
      } else if (template_type === "monthly_template") {
        stroke = "#F4DB77";
        primaryColor = "#FFFDF7";
        secondaryColor = "#FFF1B8";
      } else {
        stroke = "#EEB0B0";
        primaryColor = "#FFF8F8";
        secondaryColor = "#FFE0E0";
      }

      setModalData({
        data: response?.data?.qa,
        stroke: stroke,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        template_title,
        description: response?.data?.user_answer?.template_description,
        template: template,
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const getTouchbase = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id, startDate, endDate;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      if (selectedDate) {
        startDate = convertDateToFormat(selectedDate[0]);
        endDate = convertDateToFormat(selectedDate[1]);
      }
      const result = await api.get(baseURL + "/admin-touchbase/", {
        session: filterSession,
        company_id: company_id,
        start_date: startDate || null,
        end_date: endDate || null,
      });
      setData(result?.data);
    } catch (error) {
      if (error?.response?.status === 404) {
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getTouchbase();
  }, [selectedDate, filterSession]);

  const handleChangeOption = (value) => {
    setSelectedOption(value);
  };

  const handleChange = (dates, dateStrings) => {
    setSelectedDate(dateStrings);
  };

  const handleOpenChange = (open) => {
    if (!open) {
      // Handle the case where the calendar closes
    }
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case "on_track":
        return {
          textColor: "#55AC2F",
          backgroundColor: "#EDFCE6",
          displayText: "On Track",
        };
      case "off_track":
        return {
          textColor: "#DB7F7F",
          backgroundColor: "#FFEBEB",
          displayText: "Off Track",
        };
      case "behind":
        return {
          textColor: "#DDAE23",
          backgroundColor: "#FFFAEA",
          displayText: "Behind",
        };
      case "in_progress":
        return {
          textColor: "#6690D7",
          backgroundColor: "#ECF3FF",
          displayText: "In Progress",
        };
      default:
        return {
          textColor: "#D1D1D1",
          backgroundColor: "#F0F0F0",
          displayText: "No Status",
        };
    }
  };

  const getStrokeColor = (template_type) => {
    if (template_type === "quarterly_template") {
      return "#92CAC1";
    } else if (template_type === "monthly_template") {
      return "#F4DB77";
    } else {
      return "#EEB0B0";
    }
  };
  const getValueColor = (value) => {
    let color, backgroundColor;

    if (value >= 0 && value <= 39) {
      color = "#FF0000";
      backgroundColor = "#FFDDDD";
    } else if (value >= 40 && value <= 69) {
      color = "#FFA500";
      backgroundColor = "#FFE5CC";
    } else if (value >= 70) {
      color = "#008000";
      backgroundColor = "#E5F5E0";
    } else {
      color = "#000000";
      backgroundColor = "#FFFFFF";
    }

    return { color, backgroundColor };
  };

  return (
    <CustomScrollbar>
      <div className="flex space-x-5 overflow-y-auto ">
        <div className="p-4 bg-white rounded-lg w-full space-y-5 font-sans">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              {!isMobile && ( // Conditionally render RangePicker for non-mobile screens
                <RangePicker
                  onChange={handleChange}
                  onOpenChange={handleOpenChange}
                  style={{
                    width: isMobile ? "100%" : "auto", // Full width on mobile, auto otherwise
                    minWidth: isMobile ? "100%" : "200px",
                  }}
                  className="border border-gray-300 rounded text-sm"
                  format="DD MMM, YYYY"
                />
              )}
            </div>
            <div className="flex items-center space-x-3">
              <p className="text-sm flex items-center space-x-2 text-gray-700 font-semibold">
                <SortIcon fontSize="small" />
                <span>Sort by</span>
              </p>
              <Select
                value={selectedOption}
                onChange={handleChangeOption}
                className="w-28 bg-gray-100 rounded text-sm text-gray-500 font-semibold"
                suffixIcon={<KeyboardArrowDownIcon />}
              >
                <Option value="all">All</Option>
                <Option value="monthly_template">Monthly</Option>
                <Option value="weekly_template">Weekly</Option>
                <Option value="quarterly_template">Quarterly</Option>
              </Select>

              <div className="flex-grow text-right">
                <div className="flex text-right items-center justify-end gap-x-2">
                  <button onClick={handlePrevious} className="">
                    <FaCaretLeft />
                  </button>
                  <div className="text-right">{currentSession?.label}</div>
                  <button onClick={handleNext} className="">
                    <FaCaretRight />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-300 my-2"></div>

          <div className="overflow-x-auto ">
            <table className="min-w-full border-collapse border-l-0 border-r-0 border-b-0 border-gray-200">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2 font-normal">
                    Goals
                  </th>
                  <th
                    className="border border-gray-300 p-2 font-normal"
                    colSpan={2}
                  >
                    Task Name
                  </th>
                  <th className="border border-gray-300 p-2 font-normal">
                    Assignee
                  </th>
                  <th className="border border-gray-300 p-2 font-normal">
                    Status
                  </th>
                  <th className="border border-gray-300 p-2 font-normal">
                    Progress
                  </th>
                  <th className="border border-gray-300 p-2 font-normal">
                    Role
                  </th>
                  <th className="border border-gray-300 p-2 font-normal">
                    Timeline
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((goal) => (
                  <React.Fragment key={goal?.goal_id}>
                    <tr>
                      <td
                        className="border-l-0 border-b-0 flex gap-x-2  border-white p-2"
                        rowSpan={1}
                      >
                        <button>
                          <img
                            src="/assets/images/goal_admin.png"
                            alt="Goal Admin"
                            style={{ width: "28px", height: "28px" }}
                          />
                        </button>
                        <p className="flex items-center justify-start font-semibold text-sm">
                          {goal?.goal_title}
                        </p>
                      </td>
                    </tr>
                    {goal?.templates.map((template) => {
                      const { backgroundColor, color } = getValueColor(
                        template?.value
                      );

                      return (
                        <React.Fragment key={template?.template_id}>
                          <tr>
                            <td colSpan={8} className="pt-8"></td>
                          </tr>
                          <tr>
                            <td colSpan={1} rowSpan={1}></td>
                            <div className="flex gap-2 items-center mb-3">
                              <div className="ml-5 text-sm flex font-semibold space-x-4 items-center px-3 py-1 border border-gray-300 rounded-md">
                                <h1 className="flex items-center space-x-1">
                                  <ShowChartIcon fontSize="small" />
                                  <p className="flex justify-center p-2">
                                    Overall Completion
                                  </p>
                                </h1>
                                <h1
                                  className="p-2 rounded-md flex justify-center"
                                  style={{
                                    backgroundColor: backgroundColor,
                                    color: color,
                                  }}
                                >
                                  {template?.value} %
                                </h1>
                              </div>
                            </div>
                            <td colSpan={4} rowSpan={1}></td>
                          </tr>
                          <tr>
                            <td className="border-none p-2">
                              <h1
                                className="text-sm font-semibold flex items-center justify-between"
                                style={{
                                  color: getStrokeColor(
                                    template?.template_type
                                  ),
                                }}
                              >
                                <CircleIcon
                                  fontSize=""
                                  className="text-current"
                                />
                                {template?.template_title}
                              </h1>
                            </td>
                            <td
                              className="border border-gray-300 p-2"
                              colSpan={1}
                            >
                              <div className="flex items-center justify-center font-semibold">
                                <StopIcon
                                  fontSize="small"
                                  className="text-[#3BB1CF]"
                                />
                                <p>Objective</p>
                              </div>
                            </td>
                            <td className="border border-gray-300 p-2 ">
                              <div className="flex items-center justify-center font-semibold">
                                <StopIcon
                                  fontSize="small"
                                  className="text-[#3BB1CF]"
                                />
                                <p>Key Results</p>
                              </div>
                            </td>
                            <td className="border-none p-2"></td>
                            <td className="border-none p-2"></td>
                            <td className="border-none p-2"></td>
                          </tr>
                          {template?.key_results?.length > 0 ? (
                            template?.key_results?.map((keyResult, index) => (
                              <tr key={index}>
                                <td className="border-none p-2">
                                  <h1 className="flex items-center justify-end">
                                    <TopicIcon
                                      fontSize="small"
                                      className="text-gray-300 cursor-pointer"
                                      onClick={() =>
                                        handleTemplateOpen({
                                          participant_id:
                                            keyResult?.participant_id,
                                          schedule_id: template?.schedule_id,
                                          template_id: template?.template_id,
                                          template_title:
                                            template?.template_title,
                                          template: template,
                                        })
                                      }
                                    />
                                  </h1>
                                </td>
                                <td
                                  className="border border-gray-300 p-2"
                                  colSpan={1}
                                >
                                  <p className="flex items-center justify-center font-semibold text-xs">
                                    {keyResult?.okr_title || "-"}
                                  </p>
                                </td>
                                <td className="border border-gray-300 p-2">
                                  <p className="flex items-center justify-center font-semibold text-xs">
                                    {keyResult?.key_result_title || "-"}
                                  </p>
                                </td>
                                <td className="border border-gray-300 p-2">
                                  <p className="flex items-center justify-center font-semibold text-xs  bg-[#FFFAEA] rounded-full py-1">
                                    {keyResult?.participant_name ||
                                      "No Participant"}
                                  </p>
                                </td>
                                <td className="border border-gray-300 p-2">
                                  {(() => {
                                    const {
                                      textColor,
                                      backgroundColor,
                                      displayText,
                                    } = getStatusStyles(
                                      keyResult?.project_status
                                    );
                                    return (
                                      <p
                                        className="flex items-center justify-center font-semibold text-xs rounded-full p-3"
                                        style={{
                                          color: textColor,
                                          backgroundColor: backgroundColor,
                                        }}
                                      >
                                        {displayText}
                                      </p>
                                    );
                                  })()}
                                </td>

                                <td
                                  className="border border-gray-300 p-2"
                                  style={{
                                    backgroundColor:
                                      keyResult?.overall_gain == null
                                        ? "#FFFAEA"
                                        : keyResult?.overall_gain < 40
                                        ? "#FFDDDD"
                                        : keyResult?.overall_gain < 70
                                        ? "#FFE5CC"
                                        : "#E5F5E0",
                                  }}
                                >
                                  <p className="flex items-center justify-center font-semibold text-xs py-2">
                                    {keyResult?.overall_gain != null
                                      ? `${keyResult?.overall_gain} %`
                                      : "-"}
                                  </p>
                                </td>
                                <td className="border border-gray-300 p-2">
                                  <p className="flex items-center justify-center font-semibold text-xs">
                                    Manager
                                  </p>
                                </td>
                                <td className="border border-gray-300 p-2">
                                  <p className="flex items-center justify-center font-semibold text-xs">
                                    {currentSession?.label}
                                  </p>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="border-none p-2" colSpan={8}>
                                <p className="text-center text-gray-500">
                                  No Data
                                </p>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {isModalOpen && (
          <TemplateModal
            data={modalData?.data}
            setTemplateModal={setIsModalOpen}
            templateModal={isModalOpen}
            description={modalData?.description}
            template={modalData?.template}
            title={modalData?.template_title}
            primaryColor={modalData?.primaryColor}
            secondaryColor={modalData?.secondaryColor}
            readOnly={true}
          />
        )}
      </div>
    </CustomScrollbar>
  );
};

export default AdminView;
