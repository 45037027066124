// import { Page, Paragraph, theme } from '@gilbarbara/components';
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useAppContext } from "./context";
// import Header from './Header';
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { PiStarFour } from "react-icons/pi";
import { useState } from "react";

export default function MultiRouteWrapper() {
  const {
    setState,
    state: { run, stepIndex, steps, selectedtab },
  } = useAppContext();
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  useMount(() => {
    setState({
      steps: [
        {
          target: ".first_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-1: Create a Goal
              </Heading>
              <Text>Outline and define each of your goals.</Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".second_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-2: Create an Objective
              </Heading>
              <Text>Outline and define each of your objectives.</Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".third_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-3: Create a Report
              </Heading>
              <Text>
                Generate reports encompassing all your goals, objectives, and
                key results.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".fourth_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-4: Display an Insight
              </Heading>
              <Text>
                Review all the insights details following the creation of goals,
                objectives, and key results.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".fifth_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-5: Display an Average Report
              </Heading>
              <Text>
                Track the overall progress towards your goals, objectives, and
                key results.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".sixth_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-6: Create Role
              </Heading>
              <Text>
              Create a role and use it to assign permissions while inviting employees.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".seventh_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-7: Invite a Team Member
              </Heading>
              <Text>
                Invite individuals to be part of your collaborative team effort.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".eight_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-8: Create a Team
              </Heading>
              <Text>Build a team for effective collaboration and success.</Text>
            </>
          ),
          disableBeacon: true,
        },
      ],
    });
  });

  //   const handleNavigate = (newTab) => {
  //     setState({ selectedtab: newTab });
  //     navigate(`/company/insight?tab=${encodeURIComponent(newTab)}`, { replace: true });
  //   };

  const handleCallback = (data) => {
    const { action, index, lifecycle, type } = data;
    console.log("action", action);
    if (type === "step:after") {
      switch (index) {
        case 0:
          if (action === "next") {
            setState({ run: false });
            navigate("/company/goals");
          } else if (action === "prev") {
            setState({ run: false });
            navigate("home");
          }
          break;
        case 1:
          if (action === "next") {
            setState({ run: false, selectedtab: "Reports" });
            navigate("/company/insight?tab=Reports", { replace: true });
          } else if (action === "prev") {
            setState({ run: true, stepIndex: 0 });
            navigate("/company/goals");
          }
          break;

        case 2:
          if (action === "next") {
            setState({ run: false, selectedtab: "Executive Insights" });
            navigate("/company/insight?tab=Executive Insights", {
              replace: true,
            });
          } else if (action === "prev") {
            setState({ run: true, stepIndex: 1, selectedtab: "Reports" });
            navigate("/company/insight?tab=Reports", { replace: true });
          }
          break;

        case 3:
          if (action === "next") {
            setState({ run: false, selectedtab: "Strategic Report" });
            navigate("/company/insight?tab=Strategic Report", {
              replace: true,
            });
          } else if (action === "prev") {
            setState({
              run: true,
              stepIndex: 2,
              selectedtab: "Executive Insights",
            });
            navigate("/company/insight?tab=Executive Insights", {
              replace: true,
            });
          }
          break;

        case 4:
          if (action === "next") {
            setState({ run: false, selectedtab: "Role Access" });
            navigate("/settings?tab=Role Access", { replace: true });
          } else if (action === "prev") {
            setState({
              run: true,
              stepIndex: 3,
              selectedtab: "Strategic Report",
            });
            navigate("/company/insight?tab=Strategic Report", {
              replace: true,
            });
          }
          break;

          case 5:
          if (action === "next") {
            setState({ run: false, selectedtab: "Employees" });
            navigate("/company/people?tab=Employees", { replace: true });
          } else if (action === "prev") {
            setState({ run: true, stepIndex: 4,selectedtab: "Role Access" });
            navigate("/settings?tab=Role Access", { replace: true });
          }
          break;

        case 6:
          if (action === "next") {
            setState({ run: false, selectedtab: "Teams" });
            navigate("/company/people?tab=Teams", { replace: true });
          } else if (action === "prev") {
            setState({ run: true, stepIndex: 5, selectedtab: "Employees" });
            navigate("/company/people?tab=Employees", { replace: true });
          }
          break;

        case 7:
          if (action === "next") {
            setState({ run: false, stepIndex: 0, tourActive: false });
            localStorage.removeItem("startTour");
            navigate("/home");
          } else if (action === "prev") {
            setState({ run: true, stepIndex: 6, selectedtab: "Employees" });
            navigate("/company/people?tab=Employees");
          }
          break;

        default:
          break;
      }
    } else if (
      action === "skip" ||
      action === "reset" ||
      lifecycle === "complete"
    ) {
      setState({ run: false, stepIndex: 0, tourActive: false });
      localStorage.removeItem("startTour");
      navigate("/home");
    }
  };

  return (
    <Box>
      {/* <Header /> */}
      {/* <Outlet /> */}
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        hideCloseButton
        showProgress
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        // placement={'top, top-start, top-end'}
        // title={'heelo'}
        // isFixed
        // debug
        // disableOverlay
        disableScrolling
        // disableScrollParentFix
        // floaterProps
        // nonce
        // spotlightClicks
        styles={{
          options: {
            arrowColor: "#E5EFF5", // Light color for the arrow
            backgroundColor: "#E5EFF5", // Solid light background color
            primaryColor: "#FFB319", // Bright primary color for highlights
            textColor: "#063F5D", // Darker text color for readability
            overlayColor: "rgba(0, 0, 0, 0.5)", 
            height: 280, // Balanced height for a card look
            padding: "20px", // Moderate padding for spacing
            borderRadius: "12px",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)", // Enhanced shadow for depth
            zIndex: 1000, // Layer above other content
          },
        }}
      />
    </Box>
  );
}
