import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import { Employees } from "../../pages/People/Employees";
import { Teams } from "../../pages/People/Teams";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../StepByStepGuide/context";

const People = () => {
  const dispatch = useDispatch();
  const {
    setState,
    state: { tourActive, selectedtab },
  } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "Employees";
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [isDropdown, setIsDropdown] = useState(false);

  const handleResize = () => {
    setIsDropdown(window.innerWidth < 920); // Toggle dropdown based on screen size
  };

  React.useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for screen resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  useEffect(() => {
    if (selectedtab) {
      setSelectedTab(selectedtab);
    }
  }, [selectedtab]);

  useEffect(() => {
    setSearchParams({ tab: selectedTab });

    dispatch(
      updateTitleData({
        title: "People",
        ClickLink: `People / ${selectedTab}`,
      })
    );
  }, [dispatch, selectedTab, setSearchParams]);

  const tabs = ["Employees", "Teams"];

  return (
    <div className="relative h-screen p-4">
      {isDropdown ? (
        <div className="relative mb-4">
          <select
            value={selectedTab}
            onChange={(e) => setSelectedTab(e.target.value)}
            className="p-2 border border-gray-300 rounded"
            style={{
              width: window.innerWidth < 920 ? "100vw" : "300px", // Full width on small screens, fixed on larger screens
              maxWidth: "100%", // Ensures it doesn't overflow the screen
            }}
          >
            {tabs?.map((tab) => (
              <option key={tab} value={tab}>
                {tab}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="flex items-center space-x-16 px-5">
          {tabs?.map((tab) => (
            <h1
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`relative cursor-pointer ${
                selectedTab === tab ? "text-orange-500 font-sans" : ""
              }`}
            >
              {tab}
              {selectedTab === tab && (
                <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
              )}
            </h1>
          ))}
        </div>
      )}
      <div className="border-b-2 border-gray-300 mb-4 mt-2"></div>
      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        {selectedTab === "Employees" && <Employees />}
        {selectedTab === "Teams" && <Teams />}
      </div>
    </div>
  );
};

export default People;
