import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ContactUs from "../ContactUs";

const CustomContactUs = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Contact Us
        </ModalHeader>
        <ModalCloseButton /> {/* Close icon button */}
        <ModalBody>
          <ContactUs />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomContactUs;
