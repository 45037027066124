import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInitials } from "../utils/helpers";
import EmployeeHeader from "../components/global/EmployeeHeader";
import CustomScrollbar from "../components/global/CustomScrollBar";
import { IoIosArrowRoundForward } from "react-icons/io";
import { updateTitleData } from "../stores/slices/userAuthSlice";

const DashboardMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const currentEmployee = currentUser?.employees[user?.currentCompany];

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "Dashboard",
        ClickLink: "Company / Dashboard",
      })
    );
  }, [dispatch]);

  const CompanyGoalsCard = ({
    title,
    description,
    Icon,
    BackgroundImage,
    Navigate,
  }) => {
    return (
      <div
        className="w-full sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl mx-auto font-lato bg-white rounded-xl shadow-md overflow-hidden flex flex-col justify-between"
        style={{ minHeight: "350px" }}
      >
        <div className="p-6">
          <div className="flex items-center space-x-5">
            <img className="h-20 w-20" src={Icon} alt={title} />
            <div>
              <div className="text-md text-[#3D3B36] font-semibold">
                {title}
              </div>
              <p className="text-[#979795] font-medium">{description}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center p-2">
          <img
            className="w-3/5 h-auto sm:w-full sm:h-auto md:w-3/5 md:h-auto lg:w-2/3 lg:h-auto xl:w-1/2 xl:h-auto"
            src={BackgroundImage}
            alt="data"
          />
        </div>

        <div className="p-3">
          <div className="flex justify-between items-center bg-[#F9FAFC] rounded-md">
            <button className="ml-2 text-[#3D3B3B] font-semibold">
              See Details
            </button>
            <button
              onClick={() => navigate(Navigate)}
              className="flex justify-center items-center group transition-all duration-300"
            >
              <IoIosArrowRoundForward
                className="transform transition-transform duration-300 group-hover:translate-x-2"
                size={30}
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-screen p-4 font-lato">
      <EmployeeHeader
        companyName={currentEmployee?.company_name}
        initials={getInitials(currentEmployee?.company_name)}
      />
      <div
        className="flex flex-col px-6 py-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "95%",
          // height: window.innerWidth >= 768 ? "80vh" : "100vh",
        }}
      >
        <div className="overflow-y-auto mb-5">
          <CustomScrollbar>
            <div className="grid gap-4 font-lato sm:grid-cols-1 md:grid-cols-2">
              <CompanyGoalsCard
                title="Company Goals"
                description="We are committed to excellence, customer satisfaction, and continuous improvement in all aspects of our business."
                Icon="/assets/images/companygoals.png"
                BackgroundImage="/assets/images/companygraph.png"
                Navigate="/company/goals"
              />
              <CompanyGoalsCard
                title="Teams"
                description="We are committed to excellence, customer satisfaction, and continuous improvement in all aspects of our business."
                Icon="/assets/images/companyteams.png"
                BackgroundImage="/assets/images/TeamsIcon.png"
                Navigate="/company/people?tab=Teams"
              />
              <CompanyGoalsCard
                title="Insights"
                description="We are committed to excellence, customer satisfaction, and continuous improvement in all aspects of our business."
                Icon="/assets/images/companyinsights.png"
                BackgroundImage="/assets/images/companyinsightsbackground.png"
                Navigate="/company/insight"
              />
              <CompanyGoalsCard
                title="Reports"
                description="We are committed to excellence, customer satisfaction, and continuous improvement in all aspects of our business."
                Icon="/assets/images/companyreports.png"
                BackgroundImage="/assets/images/companyreportsbackground.png"
                Navigate="/company/insight?tab=Reports"
              />
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
