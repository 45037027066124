import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOpenPlanModal,
  handleUpdateCurrentCompany,
  handleUpdatePlan,
  logout,
} from "../../stores/slices/userAuthSlice";
import { LogoutOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Tooltip } from "antd";
import SwitchAccount from "./SwitchAccount";
import ProfileModal from "./ProfileModal";
import NotificationModal from "../Notification";
import { NotifyURL, baseURL } from "../../utils/config";
import CreateGoalModal from "../Okrs/Goals/CreateGoalModal";
import {
  handleCloseCreateGoalModal,
  handleCreateRoleDrawer,
  handleCreateTeamDrawer,
  handleOpenCreateGoalModal,
  handleOpenCreateOkrModal,
  handleOpenCreateReport,
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
  handleOpenScheduleModal,
  handleOpenTeamDrawer,
  handleRefresh,
} from "../../stores/slices/okrSlice";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { handleUpdateDraftSlice } from "../../stores/slices/draftSlice";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CustomScrollbar from "./CustomScrollBar";
import { ToastContainer, toast } from "react-toastify";
import { api } from "./api";
import Cookies from "js-cookie";
import {
  useAppContext,
  useAppTouchBaseContext,
} from "../StepByStepGuide/context";

const MobileSearchAndButton = () => {
  const connection = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userold = useSelector((state) => state.user);
  const matchRoute = useMatch("/company/goals");
  const roleAccess = userold?.roleAccess;
  const currentUser = userold?.currentUser;
  const [isActiveMenuOpen, setIsActiveMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const currentEmployee = currentUser?.employees[userold?.currentCompany];
  const roleName = currentEmployee?.rolename;
  console.log("Hello", currentUser);
  const [profileModal, setProfileModal] = useState(false);
  const [data, setData] = useState([]);
  const okrSlice = useSelector((state) => state.okr);
  const [count, setCount] = useState(0);
  const divRef = useRef(null);
  const plan = userold?.currentPlan;

  const {
    setState,
    state: { run },
  } = useAppContext();

  const {
    setTouchBaseState,
    TouchBasestate: { run: touchBaseRun },
  } = useAppTouchBaseContext();

  useEffect(() => {
    const showstep = localStorage.getItem("startTour");
    if (showstep) {
      setState({ run: true, tourActive: true });
    }
  }, []);

  useEffect(() => {}, [currentUser]);

  const items = [
    {
      key: "1",
      onClick: () => {
        setProfileModal(true);
      },
      label: (
        <div
          target="_blank"
          rel="noopener noreferrer"
          className="w-24"
          href="https://www.antgroup.com"
        >
          Manage Profile
        </div>
      ),
    },

    {
      key: "4",
      label:
        currentUser?.employees?.length > 1 ? (
          <div
            onClick={() => {
              setOpenModal(true);
            }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.luohanacademy.com"
          >
            Switch Companies
            <hr />
          </div>
        ) : null,
    },

    {
      key: "3",
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            Cookies.remove("access_token");
            dispatch(logout());
          }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          <LogoutOutlined className="mr-2" />
          Logout
        </div>
      ),
    },
  ];
  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleCompanyChange = (index) => {
    dispatch(handleUpdateCurrentCompany(index));
    setOpenModal(false);
    window.location.href = "/home";
  };

  const handleMenuOpen = () => {
    setIsActiveMenuOpen(!isActiveMenuOpen);
  };

  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleNotificationOpen = () => {
    if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
      dispatch(handleOpenPlanModal());
    } else {
      setNotificationOpen(!notificationOpen);
    }
  };

  const handleCount = (countNew) => {
    setCount(countNew);
  };

  const Notificationcall = async () => {
    try {
      const response = await api.get(baseURL + "/notification/", {
        company_id: currentEmployee?.company_id,
        room_id: currentEmployee?.room_id,
      });
      setData(response?.data?.notifications);
      handleCount(response?.data?.unseen_count);
    } catch (error) {}
  };

  useEffect(() => {
    Notificationcall();
  }, [currentUser]);

  const deleteAllnotification = async () => {
    try {
      const res = await api.delete(baseURL + `/notification/`, {
        company_id: currentEmployee?.company_id,
        room_id: currentEmployee?.room_id,
      });
      Notificationcall();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationClick = async ({ index, item }) => {
    if (!item?.is_seen) {
      setupWebSocket.current = false;
      try {
        const result = await api.put(baseURL + "/notification/", {
          isSeen: "true",
          notify_id: item?.notify_id,
        });
        Notificationcall();
        setupWebSocket();
      } catch (err) {
        console.log(err);
        Notificationcall();
        setupWebSocket();
      }
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      const res = await api.delete(baseURL + `/notification/`, {
        id: id,
        room_id: currentEmployee?.room_id,
      });
      Notificationcall();
    } catch (error) {
      console.log(error);
    }
  };

  const setupWebSocket = () => {
    const socket = new WebSocket(NotifyURL + `${currentEmployee?.company_id}/`);
    socket.addEventListener("open", (event) => {
      socket.send("Connection established");
    });

    const handleMessage = (event) => {
      const newData = JSON.parse(event.data);

      if (newData?.notify_id) {
        setData((prevData) => {
          const isNewDataUnique = !prevData.some(
            (item) => item.notify_id === newData.notify_id
          );

          if (isNewDataUnique) {
            setCount((prevCount) => prevCount + 1);
            return [newData, ...prevData];
          } else {
            return prevData;
          }
        });
      }
    };

    socket.addEventListener("message", handleMessage);

    connection.current = socket;
  };

  useEffect(() => {
    if (!setupWebSocket.current) {
      setupWebSocket();
      setupWebSocket.current = true;
    }
  }, []);
  const [titleError, setTitleError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [sessionError, setSessionError] = useState(false); // State variable to track the selected option
  const [results, setResults] = useState([]);
  const [formDatanew, setformDatanew] = useState({
    session: okrSlice?.session || null,
    description: "",
    title: "",
    owner: [],
  });
  useEffect(() => {
    setformDatanew({
      session: null,
      title: "",
      owner: [],
      description: "",
    });
  }, [okrSlice?.openCreateGoalModal]);

  const [createGoalLoading, setCreateGoalLoading] = useState(false);
  const getGoals = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[userold?.currentCompany]?.company_id;
      }
      const result = await api.get(baseURL + "/goal/", {
        company_id: company_id,
        session: okrSlice?.session,
      });
    } catch (error) {
      if (error?.response?.status === 404) {
      }
      console.log(error);
    }
  };

  const showModal = () => {
    dispatch(handleOpenCreateGoalModal({ isOpen: true }));
  };

  const matchGoalRoute = useMatch("/company/goals");
  const matchTouchbaseRoute = useMatch("/touchbase/schedule");
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get("tab");

  const matchPeopleRoute = currentTab === "Employees";
  const matchTeamRoute = currentTab === "Teams";

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformDatanew((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const getCurrentPlanData = async () => {
    try {
      const currentCompany =
        userold?.currentUser?.employees[userold?.currentCompany];
      const result = await api.get(baseURL + "/subscription/", {
        company_id: currentCompany?.company_id,
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const saveData = async (event) => {
    event.preventDefault();
    let user_id = [];
    let team_id = [];
    let parent;
    let parent_type;
    if (
      formDatanew?.session === null ||
      formDatanew.session === undefined ||
      formDatanew?.session === ""
    ) {
      setSessionError(true);
      return;
    }
    if (!formDatanew.title) {
      setTitleError(true);
      return;
    }
    if (!formDatanew?.owner?.length) {
      setOwnerError(true);

      return;
    } else {
      formDatanew?.owner?.forEach((item) => {
        let typeArr = item.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    }
    if (formDatanew?.parent_val) {
      const parentArr = formDatanew?.parent_val.split(" ");
      parent = parentArr[0];
      parent_type = parentArr[1];
    }

    try {
      setCreateGoalLoading(true);
      const currentEmployee = currentUser?.employees;
      let company_id;
      let login_user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[userold?.currentCompany]?.company_id;
        login_user_id = currentEmployee[userold?.currentCompany]?.user_id;
      }

      const result = await api.post(baseURL + "/goal/", {
        title: formDatanew?.title,
        user_id: user_id,
        team_id: team_id,
        description: formDatanew?.description,
        company_id: company_id,
        session: formDatanew?.session,
        parent: parent,
        parent_type: parent_type,
      });

      getCurrentPlanData();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "goal_created",
        message: "Goal created succesfully!.",
        id: result?.data?.goal_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now())?.toLocaleString(),
      });
      navigate(`/company/goals?session=${formDatanew?.session}`);

      setformDatanew({
        session: okrSlice?.session || null,
        title: "",
        owner: [],
        description: "",
      });
    } catch (err) {
      console.log(err);

      setformDatanew({
        session: okrSlice?.session || null,
        title: "",
        owner: [],
        description: "",
      });
    } finally {
      getGoals();
      dispatch(handleCloseCreateGoalModal({ isOpen: false }));
      setCreateGoalLoading(false);
    }
  };

  const [draftReports, setDraftReports] = useState();

  const fetchData = async () => {
    try {
      const currentCompany = currentUser?.employees[userold?.currentCompany];
      const res = await api.get(baseURL + "/stats/", {
        company_id: currentCompany?.company_id,
      });
      setDraftReports(res.data || []);
      dispatch(handleUpdateDraftSlice(res.data || []));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = () => {
    if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
      dispatch(handleOpenPlanModal());
      return;
    } else if (location.pathname === "/company/insight") {
      dispatch(handleOpenCreateReport());
    } else if (matchGoalRoute) {
      if (roleAccess?.Objective?.Create) {
        dispatch(handleOpenCreateOkrModal(true));
      } else {
        toast.error("You dont have access to this feature.");
      }
    } else if (matchTouchbaseRoute) {
      if (roleAccess?.Schedules?.Create) {
        dispatch(handleOpenScheduleModal(true));
      } else {
        toast.error("You dont have access to this feature.");
      }
    } else if (matchPeopleRoute) {
      if (
        plan?.remaining_team_members > 0 ||
        plan?.remaining_team_members === -1
      ) {
        if (roleAccess?.["Invite-Members"]?.Invite)
          dispatch(handleOpenTeamDrawer());
        else {
          toast.error("You dont have access to this feature.");
        }
      } else {
        if (roleName === "Admin") {
          dispatch(handleOpenPlanModal());
        }
      }
    } else if (matchTeamRoute) {
      if (roleAccess?.Teams?.Create) {
        console.log("role", roleAccess?.Teams?.Create);
        dispatch(handleCreateTeamDrawer());
      } else {
        toast.error("You dont have access to this feature.");
      }
    } else if (location.pathname === "/settings") {
      if (currentEmployee?.rolename === "Admin") {
        dispatch(handleCreateRoleDrawer());
      } else {
        toast.error("You dont have access to this feature.");
      }
    } else {
      if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
        dispatch(handleOpenPlanModal());
        return;
      } else {
        if (roleAccess?.Goal?.Create) {
          showModal();
        } else {
          toast.error("You dont have access to this feature.");
        }
      }
    }
  };

  return (
    <div className="absolute items-center justify-between p-2 w-full">
      <div className="flex flex-row w-full justify-between gap-x-2">
        <div className="flex justify-between col-span-6 gap-x-4 w-full">
          <div
            style={{ color: "#063F5D", opacity: "90%" }}
            className="font-semibold font-lato text-3xl pl-2 flex col-span-3 flex-col  justify-center items-center"
          >
            <span className="flex justify-center items-center">
              {userold?.title}
              <sup
                style={{
                  marginLeft: "5px",
                  fontSize: "18px",
                  verticalAlign: "super",
                }}
              >
                <div className="relative">
                  <div className="w-2 h-2 bg-basic rounded-full"></div>
                  <div className="w-2 h-2 bg-basic rounded-full absolute top-0 left-0 animate-ping"></div>
                  <div className="w-2 h-2 bg-basic rounded-full absolute top-0 left-0 animate-pulse"></div>
                </div>
              </sup>
            </span>
            <div
              className="font-medium font-poppins text-sm italic cursor-pointer whitespace-nowrap"
              style={{
                color: "rgba(6, 63, 93, 0.8)",
              }}
            >
              <p>{userold?.ClickLink}</p>
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-x-2 col-span-3 w-full">
            <ul className="flex flex-row items-center w-full h-full gap-x-1">
              <li className="flex items-center col-span-3 w-full">
                <button
                  onClick={handleClick}
                  className={`text-white rounded-full font-lato w-full sm:w-56 md:w-72 px-4 sm:px-6 h-12 bg-button hover:bg-button-hover text-xs sm:text-base`}
                >
                  {location.pathname === "/company/insight"
                    ? "+ Create Report"
                    : location.pathname === "/touchbase/schedule"
                    ? "+ Create Schedule"
                    : matchGoalRoute
                    ? "+ Create Objective"
                    : matchPeopleRoute
                    ? "+ Invite Member"
                    : matchTeamRoute
                    ? "+ Create Team"
                    : location.pathname === "/settings"
                    ? "+ Create Role"
                    : "+ Create Goals"}
                </button>
              </li>
              <Popover
                isLazy
                placement="bottom-start"
                isOpen={notificationOpen}
                onClose={handleNotificationOpen}
                className="flex items-center col-span-1"
              >
                <PopoverTrigger>
                  <li className="relative" onClick={handleNotificationOpen}>
                    <div className="flex items-center justify-center transition-colors cursor-pointer">
                      <span>
                        <svg
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="46" height="46" rx="23" fill="#F7F8FA" />
                          <path
                            d="M24.8694 13.8065C26.145 14.2509 27.2507 15.0812 28.0332 16.1822C28.8157 17.2833 29.2361 18.6005 29.2362 19.9513V25.2996L32.2327 28.0967C32.3385 28.1957 32.4121 28.3242 32.4439 28.4655C32.4757 28.6069 32.4643 28.7545 32.4112 28.8893C32.358 29.0241 32.2656 29.1398 32.1459 29.2214C32.0262 29.303 31.8847 29.3468 31.7398 29.347H13.7247C13.5794 29.3474 13.4373 29.304 13.317 29.2224C13.1968 29.1408 13.1039 29.0249 13.0505 28.8897C12.9971 28.7546 12.9858 28.6065 13.0178 28.4647C13.0499 28.323 13.124 28.1942 13.2303 28.0952L16.2268 25.2996V19.9513C16.227 18.6005 16.6474 17.2833 17.4299 16.1822C18.2124 15.0812 19.3181 14.2509 20.5937 13.8065C20.6791 13.3013 20.9407 12.8427 21.332 12.512C21.7234 12.1813 22.2192 11.9998 22.7315 11.9998C23.2439 11.9998 23.7397 12.1813 24.1311 12.512C24.5224 12.8427 24.784 13.3013 24.8694 13.8065Z"
                            stroke="#767D88"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.6914 32.2367C20.8404 32.6602 21.1172 33.0271 21.4835 33.2866C21.8499 33.5461 22.2878 33.6854 22.7368 33.6854C23.1857 33.6854 23.6236 33.5461 23.99 33.2866C24.3564 33.0271 24.6332 32.6602 24.7821 32.2367H20.6914Z"
                            stroke="#767D88"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </span>
                      {count !== 0 && (
                        <div
                          className="absolute top-0 flex items-center justify-center transform translate-x-2 p-0.2 text-center font-semibold text-white text-xs bg-red-500 rounded-full"
                          style={{
                            width: `${
                              13 + 4 * (count?.toString()?.length - 1)
                            }px`,
                            height: `${
                              13 + 4 * (count?.toString()?.length - 1)
                            }px`,
                          }}
                        >
                          {count}
                        </div>
                      )}
                    </div>
                  </li>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent borderColor="white">
                    <NotificationModal
                      handleNotificationOpen={handleNotificationOpen}
                      count={count}
                      divRef={divRef}
                      data={data}
                      handleNotificationClick={handleNotificationClick}
                      deleteAllnotification={deleteAllnotification}
                      handleDeleteNotification={handleDeleteNotification}
                    />
                  </PopoverContent>
                </Portal>
              </Popover>
              <li className="flex items-center col-span-3 w-full">
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <button className="transition-colors rounded p-2 w-full text-left">
                    <div className="flex items-center space-x-2">
                      <div className="flex-shrink-0">
                        <div
                          className="w-8 h-8 border border-black rounded-full flex items-center justify-center text-white"
                          style={{
                            backgroundColor: currentUser?.profile_image
                              ? "initial"
                              : getAvatarColor(currentUser?.username),
                          }}
                          size={18}
                          onClick={handleMenuOpen}
                        >
                          {currentUser?.profile_image ? (
                            <img
                              src={currentUser.profile_image}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                                display: "block",
                              }}
                            />
                          ) : currentUser?.username ? (
                            getInitials(currentUser?.username)
                          ) : (
                            "NA"
                          )}
                        </div>
                      </div>
                      <div className="flex-grow">
                        <div className="flex flex-col">
                          <Tooltip title={currentUser?.username}>
                            <span
                              className="font-medium truncate"
                              style={{ maxWidth: "150px" }}
                            >
                              {currentUser?.username}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </button>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
        {!matchRoute && (
          <Modal
            open={okrSlice.openCreateGoalModal}
            onCancel={() => {
              setformDatanew((prevFormData) => ({
                ...prevFormData,
                session: "",
                title: "",
                owner: [],
                description: "",
              }));
              dispatch(handleCloseCreateGoalModal({ isOpen: false }));
            }}
            onOk={saveData}
            okText="Create Goal"
            confirmLoading={createGoalLoading}
          >
            <CreateGoalModal
              formData={formDatanew}
              setformData={setformDatanew}
              changeHandeler={changeHandeler}
              titleError={titleError}
              ownerError={ownerError}
              sessionError={sessionError}
              setTitleError={setTitleError}
              setOwnerError={setOwnerError}
              setSessionError={setSessionError}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default MobileSearchAndButton;
