import React from "react";
import { Avatar, Progress } from "antd";
import { getAvatarColor, getInitials } from "../../utils/helpers";

const TeamReportsTable = ({ teamReports }) => {
  return (
    <div className="overflow-x-auto w-full">
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead className="bg-header text-white sticky top-0">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-center">
              Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-center">
              Members
            </th>
            <th className="border border-gray-300 px-4 py-2 text-center">
              Manager
            </th>
            <th className="border border-gray-300 px-4 py-2 text-center">
              Progress
            </th>
            <th className="border border-gray-300 px-4 py-2 text-center">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          {teamReports?.map((item, i) => (
            <tr key={i} className="hover:bg-gray-100">
              <td className="border border-gray-300 px-4 py-2 text-center">
                {item?.team_name}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <Avatar.Group
                  maxCount={2}
                  size="small"
                  maxPopoverTrigger="click"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  {item?.employees?.map((employee, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white"
                      style={{
                        backgroundColor: employee?.profile_image
                          ? "initial"
                          : getAvatarColor(employee?.username),
                      }}
                    >
                      {employee?.profile_image ? (
                        <img
                          src={employee?.profile_image}
                          alt="Profile"
                          className="rounded-full object-cover w-full h-full"
                        />
                      ) : (
                        getInitials(employee?.username)
                      )}
                    </div>
                  ))}
                </Avatar.Group>
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <Avatar.Group
                  maxCount={2}
                  size="small"
                  maxPopoverTrigger="click"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  {item?.manager?.map((manager, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white"
                      style={{
                        backgroundColor: manager?.profile_image
                          ? "initial"
                          : getAvatarColor(manager?.username),
                      }}
                    >
                      {manager?.profile_image ? (
                        <img
                          src={manager?.profile_image}
                          alt="Profile"
                          className="rounded-full object-cover w-full h-full"
                        />
                      ) : (
                        getInitials(manager?.username)
                      )}
                    </div>
                  ))}
                </Avatar.Group>
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <Progress
                  type="circle"
                  size={50}
                  percent={item?.progress}
                  strokeWidth={10}
                  strokeColor={
                    item?.progress >= 0 && item?.progress <= 29
                      ? "#FF2934"
                      : item?.progress >= 30 && item?.progress <= 49
                      ? "#FF8400"
                      : item?.progress >= 50 && item?.progress <= 69
                      ? "#FFF000"
                      : "#53DC0D"
                  }
                  trailColor="#f0f0f0"
                  format={(percent) => (
                    <span style={{ fontWeight: "medium", color: "black" }}>
                      {percent}%
                    </span>
                  )}
                />
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <div
                  className="p-2"
                  style={{
                    border: "0.96px solid rgba(168, 175, 178, 0.72)",
                    borderRadius: "7.7px",
                  }}
                >
                  {item?.description}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamReportsTable;
